import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { Alert } from '@mui/material';

type Props = {
  open: boolean;
  onClose: VoidFunction;
};

export default function RetrieveLanguagesInfoMessage({ open, onClose }: Props) {
  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { maxWidth: 720, minWidth: 300 } }}
    >
      <DialogTitle>Try to retrieve the language or metadata</DialogTitle>
      <DialogContent>
        <Alert severity="info">
          Please also check if your translations are activated.
          Navigate to your Salesforce Setup -&gt; Translation Language Settings, and enable the language you wish to translate.
        </Alert>
      </DialogContent>
      <DialogActions />
    </Dialog>
  );
}