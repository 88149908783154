export const flowAspect = [
  // {
  //   id: 'flowDefinitions.label',
  //   aspect: 'Screen Flow Version Name',
  //   selectable: false,
  //   selected: false,
  // },
  {
    id: 'flowDefinitions.screens.helpText',
    aspect: 'Screen Info Help Text',
    selectable: false,
    selected: false,
  },
  // {
  //   id: 'flowDefinitions.screens.pausedText',
  //   aspect: 'Screen Info Pause Message',
  //   selectable: false,
  //   selected: false,
  // },
  {
    id: 'flowDefinitions.choices.choiceText',
    aspect: 'Choice Field Label',
    selectable: false,
    selected: false,
  },
  {
    id: 'flowDefinitions.choices.errorMessage',
    aspect: 'Choice Error Message',
    selectable: false,
    selected: false,
  },
  {
    id: 'flowDefinitions.choices.promptText',
    aspect: 'Choice Input Label',
    selectable: false,
    selected: false,
  },
  {
    id: 'flowDefinitions.screens.fields.fieldText',
    aspect: 'Screen Component Field Label',
    selectable: false,
    selected: false,
  },
  {
    id: 'flowDefinitions.screens.fields.helpText',
    aspect: 'Screen Component Help Text',
    selectable: false,
    selected: false,
  },
  {
    id: 'flowDefinitions.screens.fields.errorMessage',
    aspect: 'Screen Component Error Message',
    selectable: false,
    selected: false,
  },
  {
    id: 'flowDefinitions.stages.label',
    aspect: 'Stage Field Label',
    selectable: false,
    selected: false,
  },
];
