import { useState, useCallback } from 'react';
import { useDashboardContext } from 'src/context/hooks';
import { EngineDataWithLanguagePair, TranslateEngine } from 'src/types/translateEngine';
import useFetcher, { endpoints } from 'src/utils/axios';

export const useFetchTranslateEngine = () => {
    const [translateEngineData, setTranslateEngineData] = useState<TranslateEngine[]>([]);
    const [translateEngineMappedLanguageDataValue, setTranslationEngineMappedLanguageDataValue] = useState<EngineDataWithLanguagePair[]>([]);
    const { currentOrg } = useDashboardContext();
    const { fetchData } = useFetcher();

    const fetchTranslateEngineData = useCallback(() => {
        if (currentOrg?.org_id) {
            return fetchData([endpoints.translateEngine.get, {
                params: { org_id: currentOrg?.org_id },
            }])
                .then((response) => {
                    setTranslateEngineData(response);
                    return response;
                })
                .catch((error) => {
                    console.error('Error fetching translation engines:', error);
                    throw error;
                });
        }
        return Promise.resolve([]);
    }, [currentOrg?.org_id, fetchData]);

    const fetchTranslateEngineLanguageMappedData = useCallback(() => {
        if (currentOrg?.org_id) {
            return fetchData([endpoints.translateEngine.languageMappingGetAll, {
                params: { org_id: currentOrg?.org_id },
            }])
                .then((response) => {
                    setTranslationEngineMappedLanguageDataValue(response);
                    return response;
                })
                .catch((error) => {
                    console.error('Error fetching translation engines:', error);
                    throw error;
                });
        }
        return Promise.resolve([]);
    }, [currentOrg?.org_id, fetchData]);

    return { translateEngineData, translateEngineMappedLanguageDataValue, fetchTranslateEngineData, fetchTranslateEngineLanguageMappedData };
};
