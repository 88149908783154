import { useCallback, useEffect, useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import useFetcher, { endpoints } from 'src/utils/axios';
// hooks
import { useBoolean } from '../../../hooks/use-boolean';
// components
import Label from '../../../components/label';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import { ConfirmDialog } from '../../../components/custom-dialog';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from '../../../components/table';
// types
import {
  ENVIRONMENT_STATUS_OPTIONS,
  IEnvironment,
  IOrgCredentialTableFilterValue,
  ITableFilters,
} from '../../../types/orgCredential';
//
import TableToolbar from '../table-toolbar';
import TableFiltersResult from '../table-filters-result';

import EnvironmentTableRowMetadata from '../environment-row';
// ----------------------------------------------------------------------

interface Props {
  environments: IEnvironment[];
  onUpdateCreds: (updatedRow: IEnvironment) => void;
}

const TABLE_HEAD = [
  { id: 'name', label: 'Env Name' },
  { id: 'api_name', label: 'API Name' },
  { id: 'type', label: 'Type' },
  { id: 'instance_url', label: 'Instance URL' },
  { id: 'languages', label: 'Languages' },
  { id: 'status', label: 'Status' },
  { id: '', width: 88 },
];

const defaultFilters: ITableFilters = {
  name: '',
  status: 'active',
  startDate: null,
  endDate: null,
};

// ----------------------------------------------------------------------

export default function ListViewEnvironment({ environments, onUpdateCreds }: Props) {
  const { deleteData, fetchData } = useFetcher();
  const table = useTable({ defaultOrderBy: 'orderNumber' });

  const confirm = useBoolean();

  const [filters, setFilters] = useState(defaultFilters);

  const [listEnvironments, setListEnvironments] = useState<IEnvironment[]>(environments);

  useEffect(() => {
    setListEnvironments(environments);
  }, [environments]);

  const dateError =
    filters.startDate && filters.endDate
      ? filters.startDate.getTime() > filters.endDate.getTime()
      : false;

  const dataFiltered = applyFilter({
    inputData: listEnvironments,
    comparator: getComparator(table.order, table.orderBy),
    filters,
    dateError,
  });

  const handleUpdateRow = useCallback(
    (updatedRow: IEnvironment) => {
      onUpdateCreds(updatedRow);
      setListEnvironments((thisEnvironments) =>
        thisEnvironments.map((env) => (env.env_id === updatedRow.env_id ? updatedRow : env))
      );
    },
    [onUpdateCreds]
  );
  const handleEditRow = useCallback(
    (env_id: string) => {
      fetchData([endpoints.environment, {}]).then((response: IEnvironment[]) => {
        setListEnvironments(response);
      });
    },
    [fetchData]
  );
  const handleDeleteRow = useCallback(
    (env_id: string) => {
      deleteData([endpoints.environment, { params: { env_id } }]).then(() => {
        setListEnvironments((thisEnvironments) =>
          thisEnvironments.filter((env) => env.env_id !== env_id)
        );
      });
    },
    [deleteData, setListEnvironments]
  );

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 40 : 72;

  const canReset =
    !!filters.name || filters.status !== 'all' || (!!filters.startDate && !!filters.endDate);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleFilters = useCallback(
    (name: string, value: IOrgCredentialTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );
  const handleDeleteRows = () => {
    table.selected.forEach((env_id) => {
      deleteData([endpoints.credential.delete, { params: { env_id } }]).then(() => {});
    });
  };
  return (
    <>
      <Card
        sx={{
          m: 1,
          p: 6,
        }}
      >
        <Tabs
          value={filters.status}
          onChange={handleFilterStatus}
          sx={{
            px: 2.5,
            boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
          }}
        >
          {ENVIRONMENT_STATUS_OPTIONS.map((tab) => (
            <Tab
              key={tab.value}
              iconPosition="end"
              value={tab.value}
              label={tab.label}
              icon={
                <Label variant="soft" color={tab.value === 'active' ? 'success' : 'error'}>
                  {tab.value === 'active' &&
                    listEnvironments.filter((data) => data.status === 'active').length}
                  {tab.value === 'inactive' &&
                    listEnvironments.filter((data) => data?.status === 'inactive').length}
                </Label>
              }
            />
          ))}
        </Tabs>
        <TableToolbar
          filters={filters}
          onFilters={handleFilters}
          //
          canReset={canReset}
          onResetFilters={handleResetFilters}
        />

        {canReset && (
          <TableFiltersResult
            filters={filters}
            onFilters={handleFilters}
            //
            onResetFilters={handleResetFilters}
            //
            results={dataFiltered.length}
            sx={{ p: 2.5, pt: 0 }}
          />
        )}

        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <TableSelectedAction
            dense={table.dense}
            numSelected={table.selected.length}
            rowCount={environments.length}
            onSelectAllRows={(checked) =>
              table.onSelectAllRows(
                checked,
                environments.map((row) => row.env_id)
              )
            }
            action={
              <Tooltip title="Delete">
                <IconButton color="primary" onClick={confirm.onTrue}>
                  <Iconify icon="solar:trash-bin-trash-bold" />
                </IconButton>
              </Tooltip>
            }
          />

          <Scrollbar>
            <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_HEAD}
                rowCount={environments.length}
                numSelected={table.selected.length}
                onSort={table.onSort}
                onSelectAllRows={(checked) =>
                  table.onSelectAllRows(
                    checked,
                    environments.map((row) => row.env_id)
                  )
                }
              />

              <TableBody>
                {dataInPage.map((row) => (
                  <EnvironmentTableRowMetadata
                    key={row.env_id}
                    row={row}
                    onDeleteRow={(id: string) => handleDeleteRow(id)}
                    selected={table.selected.includes(row.env_id)}
                    onSelectRow={() => table.onSelectRow(row.env_id)}
                    onViewRow={() => null}
                    onUpdateRow={handleUpdateRow}
                    handleEdit={handleEditRow}
                  />
                ))}

                <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, environments.length)}
                />

                <TableNoData notFound={notFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={dataFiltered.length}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
          //
          dense={table.dense}
          onChangeDense={table.onChangeDense}
        />
      </Card>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filters,
  dateError,
}: {
  inputData: IEnvironment[] | [];
  comparator: (a: any, b: any) => number;
  filters: ITableFilters;
  dateError: boolean;
}) {
  if (Array.isArray(inputData)) {
    const { status, name } = filters;
    const stabilizedThis = inputData.map((el, index) => [el, index] as const);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    inputData = stabilizedThis.map((el) => el[0]);

    if (name) {
      inputData = inputData.filter(
        (translation) =>
          translation.env_id.toLowerCase().indexOf(name.toLowerCase()) !== -1 ||
          translation.name.toLowerCase().indexOf(name.toLowerCase()) !== -1 ||
          translation.api_name.toLowerCase().indexOf(name.toLowerCase()) !== -1 ||
          translation.instance_url.toLowerCase().indexOf(name.toLowerCase()) !== -1 ||
          translation.status.toLowerCase().indexOf(name.toLowerCase()) !== -1
      );
    }
    if (status !== 'all') {
      inputData = inputData.filter((order) => order.status === status);
    }
  }
  return inputData;
}
