import { Autocomplete, Box, Button, Container, Popper, TextField } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import Label from 'src/components/label';
import PermissionDenied from 'src/components/table/permission-denied';
import usePermission from 'src/permission/has-permission';
import { EngineDataWithLanguagePair, TranslateEngine } from 'src/types/translateEngine';
import { useEffect, useState } from 'react';
import { ILanugage } from 'src/types/translatable';
import { useDashboardContext } from 'src/context/hooks';
import useFetcher, { endpoints } from 'src/utils/axios';
import { LoadingScreen } from 'src/components/loading-screen';
import { MANAGE_SETUP } from '../../permission/all_permissions';
import ListView from './list-view-translation-engine';
import CreateTranslationEngine from './create-translation-engine';
import { useFetchTranslateEngine } from './useFetchTranslateEngine';
import { languages as _languages } from '../../assets/data/languages';
import LanguageMappingDashboard from './LanguageDashboard/language-mapping-dashboard';

type Props = {
  wizzardSetupType?: string
}

export default function TranslationEngineView({wizzardSetupType}: Props) {
  const settings = useSettingsContext();
  const translateEngineCreatorBool = useBoolean();
  const isLoading = useBoolean(true);
  const { fetchTranslateEngineData, fetchTranslateEngineLanguageMappedData } =
    useFetchTranslateEngine();
  const [translateEngine, setTranslateEngine] = useState<TranslateEngine[]>([]);
  const [translateEngineMappedLanguageData, setTranslationEngineMappedLanguageData] = useState<
    EngineDataWithLanguagePair[]
  >([]);
  const { currentOrg } = useDashboardContext();
  const { fetchData } = useFetcher();
  const { allowedPermission, isPermissionLoading } = usePermission(MANAGE_SETUP.MANAGE_SETUP);
  const [languages, setLanguages] = useState<ILanugage[]>(_languages);
  const [mappedLanguages, setMappedLanguages] = useState<any[]>([]);

  const [selectedLanguage, setSelectedLanguage] = useState<ILanugage>();
  const [selectedMasterLanguageEngine, setSelectedMasterLanguageEngine] = useState<any[]>([]);
  const { postData } = useFetcher();
  const notFound = useBoolean(true);
  const languageOpener = useBoolean();

  useEffect(() => {
    const fetchTranslationEngineData = async () => {
      try {
        setTranslateEngine([]);
        setTranslationEngineMappedLanguageData([]);
        setSelectedLanguage(undefined);
        setSelectedMasterLanguageEngine([]);

        if (currentOrg?.org_id) {
          const [translateEngineData, translateEngineMappedLanguageDataValue] = await Promise.all([
            fetchTranslateEngineData(),
            fetchTranslateEngineLanguageMappedData(),
          ]);

          setTranslateEngine(translateEngineData || []);
          setTranslationEngineMappedLanguageData(translateEngineMappedLanguageDataValue || []);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        isLoading.onFalse();
      }
    };

    if (currentOrg?.org_id) {
      isLoading.onTrue();
      fetchTranslationEngineData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.org_id]);

  useEffect(() => {
    if (!selectedLanguage?.isoCode || !languages.length) {
      setSelectedMasterLanguageEngine([]);
      return;
    }

    const rows = translateEngineMappedLanguageData
      .filter((data) => data.masterLanguage === selectedLanguage.isoCode)
      .flatMap((data) =>
        data?.mappedTranslationEngine?.map((translationEngine: any) => {
          const detailedSelectedLanguageIsoCodes = translationEngine.selectedlanguageisocodes
            .map((isoCode: string) => {
              const languageDetail = languages.find((lang) => lang.isoCode === isoCode);
              return languageDetail ? { ...languageDetail } : null;
            })
            .filter(Boolean);

          const detailedConnectedLanguage = languages.find(
            (lang) => lang.isoCode === translationEngine.connectedLanguage
          );

          return {
            name: translationEngine.name,
            id: translationEngine.id,
            connectedLanguage: detailedConnectedLanguage,
            isMatched: translationEngine.isMatched,
            selectedlanguageisocodes: detailedSelectedLanguageIsoCodes,
          };
        })
      );

    setSelectedMasterLanguageEngine(rows);
  }, [selectedLanguage?.isoCode, translateEngineMappedLanguageData, languages]);

  useEffect(() => {
    if (!languages.length || !translateEngineMappedLanguageData.length) {
      setMappedLanguages([]);
      return;
    }

    const mappedData = translateEngineMappedLanguageData.map((data) => {
      const languageDetails = languages.find((lang) => lang.isoCode === data.masterLanguage);
      return {
        ...data,
        label: languageDetails?.label || 'Unknown Language',
        countryCode: languageDetails?.countryCode || 'XX',
        isoCode: data.masterLanguage,
      };
    });

    setMappedLanguages(mappedData);
  }, [translateEngineMappedLanguageData, languages]);

  const handleLanguageChange = async (id: any, newLanguage: any) => {
    const updatedEngines = selectedMasterLanguageEngine.map((engine: any) =>
      engine.id === id
        ? {
            ...engine,
            connectedLanguage: newLanguage || '',
          }
        : {
            ...engine,
          }
    );

    const updatedForm = {
      org_id: currentOrg ? currentOrg.org_id : '',
      env_id: '',
      masterLanguage: selectedLanguage?.isoCode,
      mappedTranslationEngine: selectedMasterLanguageEngine.map((engine: any) =>
        engine.id === id
          ? {
              ...engine,
              connectedLanguage: newLanguage?.isoCode || '',
              isMatched: Boolean(newLanguage?.isoCode),
              selectedlanguageisocodes: engine.selectedlanguageisocodes.map(
                (code: any) => code.isoCode
              ),
            }
          : {
              ...engine,
              connectedLanguage: engine?.connectedLanguage?.isoCode || '',
              selectedlanguageisocodes: engine.selectedlanguageisocodes.map(
                (code: any) => code.isoCode
              ),
            }
      ),
    };

    try {
      await postData(
        [
          endpoints.translateEngine.languageMappingPost,
          {
            params: {
              org_id: updatedForm?.org_id,
              masterLanguage: updatedForm?.masterLanguage,
            },
          },
        ],
        updatedForm
      );

      setSelectedMasterLanguageEngine(updatedEngines);
      const translateEngineMappedLanguageDataValue = await fetchTranslateEngineLanguageMappedData();
      // Set the fetched data to the state
      setTranslationEngineMappedLanguageData(translateEngineMappedLanguageDataValue);
    } catch (err) {
      console.error('Error posting data:', err);
    }
  };

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'id', headerName: 'ID', flex: 0.5 },
    {
      field: 'connectedLanguage',
      headerName: 'Connected Language',
      flex: 1,
      renderCell: (params) => (
        <Autocomplete
          autoHighlight
          sx={{ width: '100%' }}
          options={params.row.selectedlanguageisocodes || []}
          value={params.row.connectedLanguage || ''}
          onChange={(event, newValue) => handleLanguageChange(params.row.id, newValue)}
          PopperComponent={(props) => <Popper {...props} placement="bottom-start" />}
          renderInput={(paramsValue) => <TextField {...paramsValue} label="Select Language" />}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.countryCode.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.countryCode.toLowerCase()}.png 2x`}
                alt=""
              />
              {option.label} ({option.isoCode})
            </Box>
          )}
        />
      ),
    },
    {
      field: 'isMatched',
      headerName: 'Status',
      flex: 0.5,
      renderCell: (params) => (
        <Label variant="soft" color={params.row.isMatched ? 'success' : 'error'}>
          {params.row.isMatched ? 'Mapped' : 'Unmapped'}
        </Label>
      ),
    },
  ];

  return (
    <>
      <CustomBreadcrumbs
        heading="Translation Engine"
        links={[{ name: '' }]}
        action={
          <Button
            variant="contained"
            color="inherit"
            startIcon={<Iconify icon="mingcute:add-line" />}
            onClick={() => translateEngineCreatorBool.onTrue()}
          >
            Add Translation Engine
          </Button>
        }
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      {
        // eslint-disable-next-line no-nested-ternary
        isLoading.value || isPermissionLoading || allowedPermission === null ? (
          <LoadingScreen />
        ) : allowedPermission && !isPermissionLoading ? (
          <>
            <Container maxWidth={settings.themeStretch ? false : 'xl'}>
              <ListView
                translateEngine={translateEngine}
                setTranslateEngine={setTranslateEngine}
                setTranslationEngineMappedLanguageData={setTranslationEngineMappedLanguageData}
                wizzardSetupType={wizzardSetupType}
              />
            </Container>
            <CustomBreadcrumbs
              heading="Translation Engine Language Mapping"
              links={[{ name: '' }]}
              sx={{
                mb: { xs: 3, md: 5 },
                mt: { xs: 3, md: 5 },
              }}
            />
            <Container maxWidth={settings.themeStretch ? false : 'xl'}>
              <LanguageMappingDashboard
                settings={settings}
                languageOpener={languageOpener}
                mappedLanguages={mappedLanguages}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                selectedMasterLanguageEngine={selectedMasterLanguageEngine}
                columns={columns}
                notFound={notFound.value}
              />
            </Container>
            <CreateTranslationEngine
              open={translateEngineCreatorBool.value}
              onClose={translateEngineCreatorBool.onFalse}
              setTranslateEngine={setTranslateEngine}
              priorityValue={translateEngine.length}
              setTranslateEngineMappedLanguageData={setTranslationEngineMappedLanguageData}
            />
          </>
        ) : (
          // eslint-disable-next-line react/jsx-boolean-value
          <PermissionDenied notFound={true} />
        )
      }
    </>
  );
}
