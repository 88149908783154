import { useContext } from 'react';
import { RealTimeContext } from './realtimeUpdates/context';
import { DashBoardContext } from './dashboard/context';

export const useDashboardContext = () => {
  const context = useContext(DashBoardContext);
  if (!context) throw new Error('useDashboardContext context must be use inside DashBoardProvider');

  return context;
};

export const useRealTimeContext = () => {
  const context = useContext(RealTimeContext);
  if (!context) throw new Error('useDashboardContext context must be use inside DashBoardProvider');

  return context;
};
