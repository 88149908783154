import * as Yup from 'yup';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';

import Grid from '@mui/material/Unstable_Grid2';

// types
import { IUserItem } from 'src/types/user';
// assets
import { countries } from 'src/assets/data';
// components
import Iconify from 'src/components/iconify';

import FormProvider, { RHFTextField, RHFAutocomplete } from 'src/components/hook-form';
import useFetcher, { endpoints } from 'src/utils/axios';
import { set } from 'lodash';

// ----------------------------------------------------------------------

type Props = {
  currentUser?: IUserItem;
};

export default function UserNewEditForm({ currentUser }: Props) {
  const { updateData } = useFetcher();

  const NewUserSchema = Yup.object().shape({
    uid: Yup.string().required(''),
    displayName: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    phoneNumber: Yup.string(),
    company: Yup.string(),

    country: Yup.string(),
    state: Yup.string(),
    zipCode: Yup.string(),
    address: Yup.string(),
  });

  const defaultValues = useMemo(
    () => ({
      uid: currentUser?.uid || '',
      displayName: currentUser?.displayName || '',
      email: currentUser?.email || '',
      phoneNumber: currentUser?.phoneNumber || '',
      company: currentUser?.company || '',
      country: currentUser?.country || '',
      state: currentUser?.state || '',
      zipCode: currentUser?.zipCode || '',
      address: currentUser?.address || '',
    }),
    [currentUser]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const { watch, setValue } = methods;

  const values = watch();

  const isSubmitting = () => {
    updateData([endpoints.users.put, {}], values);
  };

  useEffect(() => {
    setValue('uid', currentUser?.uid || '');
    setValue('displayName', currentUser?.displayName || '');
    setValue('email', currentUser?.email || '');
    setValue('phoneNumber', currentUser?.phoneNumber || '');
    setValue('company', currentUser?.company || '');
    setValue('country', currentUser?.country || '');
    setValue('state', currentUser?.state || '');
    setValue('zipCode', currentUser?.zipCode || '');
    setValue('address', currentUser?.address || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);
  return (
    <FormProvider methods={methods}>
      <Grid container spacing={1}>
        <Grid xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField name="displayName" label="Name" />
              <RHFTextField
                name="email"
                label="Email Address"
                InputProps={{
                  readOnly: true,
                }}
              />
              <RHFTextField name="phoneNumber" label="Phone Number" />
              <RHFTextField name="company" label="Company" />
              <Box sx={{ display: { xs: 'none', sm: 'block' } }} />
              <Box sx={{ display: { xs: 'none', sm: 'block' } }} />
              <RHFAutocomplete
                name="country"
                label="Country"
                options={countries.map((country) => country.label)}
                getOptionLabel={(option) => option}
                renderOption={(props, option) => {
                  const { code, label, phone } = countries.filter(
                    (country) => country.label === option
                  )[0];

                  if (!label) {
                    return null;
                  }

                  return (
                    <li {...props} key={label}>
                      <Iconify
                        key={label}
                        icon={`circle-flags:${code.toLowerCase()}`}
                        width={28}
                        sx={{ mr: 1 }}
                      />
                      {label} ({code}) +{phone}
                    </li>
                  );
                }}
              />

              <RHFTextField name="state" label="State/Region" />

              <RHFTextField name="zipCode" label="Zip/Code" />
              <RHFTextField name="address" label="Address" />
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton variant="contained" onClick={isSubmitting}>
                Save Changes
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
