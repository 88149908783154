// @mui
import { useTheme } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import WizzardDialog from './wizzard-dialog';

// ----------------------------------------------------------------------

type Props = {
  wizzardStatus: string;
};

export default function SetupWizzardIcon({ wizzardStatus }: Props) {
  const showDialog = useBoolean();
  const isCompleted = useBoolean(false);

  const theme = useTheme();

  const handleCloseWizzard = () => {
    showDialog.onFalse();
  };

  const handleWizzardCompleted = () => {
    isCompleted.onTrue();
  };

  return (
    <>
      {!isCompleted.value && (
        <Badge
          onClick={showDialog.onTrue}
          component={Button}
          sx={{
            right: 15,
            bottom: 20,
            zIndex: 999,
            display: 'flex',
            cursor: 'pointer',
            position: 'fixed',
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20,
            bgcolor: theme.palette.primary.main,
            '&:hover': { bgcolor: theme.palette.primary.dark },
          }}
        >
          <Iconify icon="ri:guide-line" color="white" width={30} />
        </Badge>
      )}
      <WizzardDialog
        open={showDialog.value}
        onClose={handleCloseWizzard}
        wizzardStatus={wizzardStatus}
        wizzardCompleted={handleWizzardCompleted}
      />
    </>
  );
}
