import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { SelectChangeEvent } from '@mui/material/Select';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import useFetcher, { endpoints } from 'src/utils/axios';
import { useDashboardContext } from 'src/context/hooks';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Alert from '@mui/material/Alert';
import {
  TranslateEngine,
  TranslateEngineItem,
  DEFAULT_GLOSSARY_NAME,
  DEFAULT_TRANSLATE_ENGINE,
  translationEngineOptions,
  EngineDataWithLanguagePair,
} from 'src/types/translateEngine';
import { GlossaryList } from 'src/types/glossary';
import { useAuthContext } from 'src/auth/hooks';
import { languages, googleTranslateLanguages, deepLLanguages } from 'src/assets/data/languages';
import { DEEPL_BATCH_SIZE, GOOGLE_TRANSLATE_BATCH_SIZE, AWS_BATCH_SIZE } from './constant';
import { useFetchTranslateEngine } from './useFetchTranslateEngine';

type Props = {
  open: boolean;
  onClose: VoidFunction;
  priorityValue: number;
  setTranslateEngine: (
    translateEngineUpdater: (prevTranslateEngine: TranslateEngine[]) => TranslateEngine[]
  ) => void;
  setTranslateEngineMappedLanguageData: (
    translateEngineUpdater: (
      prevTranslateEngine: EngineDataWithLanguagePair[]
    ) => EngineDataWithLanguagePair[]
  ) => void;
};

type DefaultValues = { name: string; url?: string; apikey?: string };

export default function TranslationEngineCreate({
  open,
  onClose,
  setTranslateEngine,
  priorityValue,
  setTranslateEngineMappedLanguageData,
}: Props) {
  const { currentOrg } = useDashboardContext();
  const { currentUser } = useAuthContext();
  const [initialAuthConfig, setInitialAuthConfig] = useState({
    translateEngineValue: DEFAULT_TRANSLATE_ENGINE,
    glossaryListValue: '',
    customEngineAuthentication: 'Bearer Token',
    desiredStructrue: 'google-translate',
    customEngineMethod: 'POST',
    translationEngineStatus: false,
    username: '',
    password: '',
    tokenPrefix: 'Bearer',
    bearerToken: '',
    authURL: '',
    tokenURL: '',
    callbackURL: '',
    clientID: '',
    clientSecret: '',
    scope: '',
    apikey: '',
    name: '',
    url: '',
    accessToken: '',
    selectedlanguageisocodes: [] as string[],
    batchSize: '',
    aws_access_key_id: '',
    aws_secret_access_key: '',
    google_cloud_project_id: '',
    google_cloud_location: '',
    priority: priorityValue + 1,
  });

  const [authConfig, setAuthConfig] = useState(initialAuthConfig);
  const [selectedLanguages, setSelectedLanguages] = useState([]);

  const [allGlossaryList, setAllGlossaryList] = useState<GlossaryList[]>([]);
  const [languageList, setLanguageList] = useState(googleTranslateLanguages);
  const { postData, fetchData, updateData } = useFetcher();
  const { fetchTranslateEngineData, fetchTranslateEngineLanguageMappedData } =
    useFetchTranslateEngine();
  const [errorMessage, setErrorMessage] = useState<string>();

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });

  const defaultValues: DefaultValues = useMemo(
    () => ({
      name: authConfig.name || '',
      url: authConfig.translateEngineValue === 'Custom-Engine' ? authConfig.url : '',
      apikey: authConfig.translateEngineValue !== 'Custom-Engine' ? authConfig.apikey : '',
    }),
    [authConfig]
  );

  const methods = useForm<any>({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const buildRequestBody = (
    updatedFields: Partial<TranslateEngineItem> = {}
  ): TranslateEngineItem => ({
    org_id: currentOrg?.org_id || '',
    priority: initialAuthConfig.priority,
    createdBy: currentUser?.uid || '',
    translationEngine: authConfig.translateEngineValue,
    translationEngineStatus: authConfig.translationEngineStatus,
    glossaryListName: '',
    name: authConfig.name,
    apikey: authConfig.apikey,
    lastModifiedBy: currentUser.uid,
    isCustomTranslationEngine: true,
    url: authConfig.url,
    customEngineAuthentication: authConfig.customEngineAuthentication,
    desiredStructure: authConfig.desiredStructrue,
    customEngineMethod: authConfig.customEngineMethod,
    username: authConfig.username,
    password: authConfig.password,
    tokenPrefix: authConfig.tokenPrefix,
    bearerToken: authConfig.bearerToken,
    authURL: authConfig.authURL,
    tokenURL: authConfig.tokenURL,
    callbackURL: authConfig.callbackURL,
    clientID: authConfig.clientID,
    clientSecret: authConfig.clientSecret,
    scope: authConfig.scope,
    accessToken: authConfig.accessToken,
    selectedlanguageisocodes: authConfig.selectedlanguageisocodes,
    batchSize: authConfig.batchSize,
    aws_access_key_id: authConfig.aws_access_key_id,
    aws_secret_access_key: authConfig.aws_secret_access_key,
    google_cloud_project_id: authConfig.google_cloud_project_id,
    google_cloud_location: authConfig.google_cloud_location,
    ...updatedFields,
  });

  const onSubmit = handleSubmit(async () => {
    if (currentOrg && currentUser) {
      const requestBody = buildRequestBody();
      try {
        const insertedData = await postData(endpoints.translateEngine.post, requestBody);
        const response = await fetchTranslateEngineData();
        if (response.length > 0) {
          setTranslateEngine(response);
          const glossaryID = allGlossaryList
            .filter(
              (glossaryListItem) => glossaryListItem.name === insertedData[0].glossaryListName
            )
            .map((glossaryListItem) => glossaryListItem._id);

          const dataWithTranslationEngine = {
            customGoogleTranslationEngine:
              insertedData[0].translationEngine === 'Google Translate' ? insertedData[0]._id : null,
            customDeeplTranslationEngine:
              insertedData[0].translationEngine === 'DeepL' ? insertedData[0]._id : null,
          };
        }
        const translationEngineMappedData = await fetchTranslateEngineLanguageMappedData();
        if (translationEngineMappedData.length > 0) {
          setTranslateEngineMappedLanguageData(translationEngineMappedData);
        }
        reset();
        setAuthConfig(initialAuthConfig);
        setSelectedLanguages([]);
        onClose();
      } catch (error) {
        console.error('Error fetching translation engines:', error);
      }
    }
  });

  const handleChange = (event: any) => {
    const { value } = event.target;

    // Filter and map to get selected ISO codes
    const selectedIsoCodes = languages
      .filter((language) => value.includes(language.label))
      .map((language) => language.isoCode);

    setAuthConfig({ ...authConfig, selectedlanguageisocodes: selectedIsoCodes });
    setSelectedLanguages(value);
  };

  const handleTranslateEngineChange = (e: SelectChangeEvent<string>) => {
    const { value } = e.target;
    setAuthConfig({ ...authConfig, translateEngineValue: value });

    let newLanguageList;

    if (value === 'Google Translate') {
      newLanguageList = googleTranslateLanguages;
    } else if (value === 'DeepL') {
      newLanguageList = deepLLanguages;
    } else {
      newLanguageList = languages;
    }

    setLanguageList(newLanguageList);
  };

  const fetchGlossaryListData = async () => {
    if (currentOrg) {
      const response = await fetchData([
        endpoints.glossary.getList,
        { params: { org_id: currentOrg.org_id } },
      ]);
      setAllGlossaryList(response);
    }
  };

  const batchSizeValue = () => {
    if (authConfig.translateEngineValue === 'DeepL') {
      authConfig.batchSize = DEEPL_BATCH_SIZE.toString();
      return DEEPL_BATCH_SIZE;
    }
    if (authConfig.translateEngineValue === 'Google Translate') {
      authConfig.batchSize = GOOGLE_TRANSLATE_BATCH_SIZE.toString();
      return GOOGLE_TRANSLATE_BATCH_SIZE;
    }
    if (authConfig.translateEngineValue === 'AWS') {
      authConfig.batchSize = AWS_BATCH_SIZE.toString();
      return AWS_BATCH_SIZE;
    }
    return authConfig.batchSize;
  };

  useEffect(() => {
    fetchGlossaryListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg]);

  useEffect(() => {
    const defaultGlossary = allGlossaryList?.find((item) => item.isdefault);
    setAuthConfig((prev) => ({
      ...prev,
      glossaryListValue: defaultGlossary ? defaultGlossary.name : DEFAULT_GLOSSARY_NAME,
    }));
  }, [allGlossaryList]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => {
    setInitialAuthConfig((prevConfig) => ({
      ...prevConfig,
      priority: priorityValue + 1,
    }));
  }, [priorityValue]);

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods}>
        <DialogTitle>Translation Engine Create</DialogTitle>
        <DialogContent>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
            sx={{ p: 2 }}
          >
            <RHFTextField
              name="name"
              label="Name"
              value={authConfig.name}
              onChange={(e) => setAuthConfig({ ...authConfig, name: e.target.value })}
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Translation Engine</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                label="Translation Engine"
                value={authConfig.translateEngineValue}
                onChange={handleTranslateEngineChange}
              >
                {translationEngineOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {authConfig.translateEngineValue === 'Custom-Engine' ? (
              <>
                <RHFTextField
                  name="url"
                  label="URL"
                  value={authConfig.url}
                  onChange={(e) => setAuthConfig({ ...authConfig, url: e.target.value })}
                />
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Method</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Method"
                    value={authConfig.customEngineMethod}
                    onChange={(e) =>
                      setAuthConfig({ ...authConfig, customEngineMethod: e.target.value })
                    }
                  >
                    <MenuItem value="GET">GET</MenuItem>
                    <MenuItem value="POST">POST</MenuItem>
                    <MenuItem value="PUT">PUT</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Authentication Protocol
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Authentication Protocol"
                    value={authConfig.customEngineAuthentication}
                    onChange={(e) =>
                      setAuthConfig({ ...authConfig, customEngineAuthentication: e.target.value })
                    }
                  >
                    <MenuItem value="Bearer Token">Bearer Token</MenuItem>
                    <MenuItem value="OAuth 2.0">OAuth 2.0</MenuItem>
                    <MenuItem value="Basic Authentication">Basic Authentication</MenuItem>
                    <MenuItem value="No Auth">No Auth</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Select Desired Structure</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Desired Structure"
                    value={authConfig.desiredStructrue}
                    onChange={(e) =>
                      setAuthConfig({ ...authConfig, desiredStructrue: e.target.value })
                    }
                  >
                    <MenuItem value="aws">AWS</MenuItem>
                    <MenuItem value="google-translate">Google Translate</MenuItem>
                    <MenuItem value="deepl">DeepL</MenuItem>
                    <MenuItem value="chat-gpt">Chat-GPT</MenuItem>
                  </Select>
                </FormControl>
                {authConfig.desiredStructrue === 'aws' && (
                  <>
                    <RHFTextField
                      name="aws_access_key_id"
                      label="AWS Access Key ID"
                      onChange={(e) => setAuthConfig({ ...authConfig, username: e.target.value })}
                    />
                    <RHFTextField
                      name="aws_secret_access_key"
                      label="AWS Secret Access Key"
                      onChange={(e) =>
                        setAuthConfig({ ...authConfig, aws_secret_access_key: e.target.value })
                      }
                    />
                  </>
                )}
                {authConfig.customEngineAuthentication === 'Basic Authentication' &&
                  authConfig.desiredStructrue !== 'aws' && (
                    <>
                      <RHFTextField
                        name="username"
                        label="Username"
                        onChange={(e) => setAuthConfig({ ...authConfig, username: e.target.value })}
                      />
                      <RHFTextField
                        name="password"
                        label="Password"
                        onChange={(e) => setAuthConfig({ ...authConfig, password: e.target.value })}
                      />
                    </>
                  )}
                {authConfig.customEngineAuthentication === 'Bearer Token' &&
                  authConfig.desiredStructrue !== 'aws' && (
                    <>
                      <RHFTextField
                        name="tokenPrefix"
                        label="Token Prefix"
                        placeholder="Bearer"
                        onChange={(e) =>
                          setAuthConfig({ ...authConfig, tokenPrefix: e.target.value })
                        }
                      />
                      <RHFTextField
                        name="bearerToken"
                        label="Bearer Token"
                        onChange={(e) =>
                          setAuthConfig({ ...authConfig, bearerToken: e.target.value })
                        }
                      />
                    </>
                  )}
              </>
            ) : (
              authConfig.translateEngineValue !== 'AWS' &&
              authConfig.translateEngineValue !== 'Google Translate' && (
                <RHFTextField
                  name="apikey"
                  label="Api Key"
                  value={authConfig.apikey}
                  onChange={(e) => setAuthConfig({ ...authConfig, apikey: e.target.value })}
                />
              )
            )}
            {(authConfig.translateEngineValue === 'Google Translate' ||
              authConfig.customEngineAuthentication === 'OAuth 2.0') && (
              <>
                <RHFTextField
                  name="authURL"
                  label="Auth URL"
                  onChange={(e) => setAuthConfig({ ...authConfig, authURL: e.target.value })}
                />
                <RHFTextField
                  name="tokenURL"
                  label="Token URL"
                  onChange={(e) => setAuthConfig({ ...authConfig, tokenURL: e.target.value })}
                />
                <RHFTextField
                  name="callbackURL"
                  label="Callback URL"
                  onChange={(e) => setAuthConfig({ ...authConfig, callbackURL: e.target.value })}
                />
                <RHFTextField
                  name="clientID"
                  label="Client ID"
                  onChange={(e) => setAuthConfig({ ...authConfig, clientID: e.target.value })}
                />
                <RHFTextField
                  name="clientSecret"
                  label="Client Secret"
                  onChange={(e) => setAuthConfig({ ...authConfig, clientSecret: e.target.value })}
                />
                <RHFTextField
                  name="scope"
                  label="Scope"
                  onChange={(e) => setAuthConfig({ ...authConfig, scope: e.target.value })}
                />
                <RHFTextField
                  name="google_cloud_project_id"
                  label="Google Cloud Project ID"
                  onChange={(e) =>
                    setAuthConfig({ ...authConfig, google_cloud_project_id: e.target.value })
                  }
                />
                <RHFTextField
                  name="google_cloud_location"
                  label="Google Cloud Location"
                  onChange={(e) =>
                    setAuthConfig({ ...authConfig, google_cloud_location: e.target.value })
                  }
                />
              </>
            )}
            {authConfig.translateEngineValue === 'AWS' && (
              <>
                <RHFTextField
                  name="aws_access_key_id"
                  label="AWS Access Key ID"
                  onChange={(e) =>
                    setAuthConfig({ ...authConfig, aws_access_key_id: e.target.value })
                  }
                  value={authConfig.aws_access_key_id}
                />
                <RHFTextField
                  name="aws_secret_access_key"
                  label="AWS Secret Access Key"
                  onChange={(e) =>
                    setAuthConfig({ ...authConfig, aws_secret_access_key: e.target.value })
                  }
                  value={authConfig.aws_secret_access_key}
                />
              </>
            )}
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Languages</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                multiple
                value={selectedLanguages}
                onChange={handleChange}
                renderValue={(selected) => selected.join(', ')}
              >
                {languageList.map((language) => (
                  <MenuItem key={language.isoCode} value={language.label}>
                    {language.label} ({language.isoCode})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {authConfig.translateEngineValue !== 'Custom-Engine' &&
              authConfig.translateEngineValue !== 'Chat-GPT' && (
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Glossary List</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Glossary List"
                    value={authConfig.glossaryListValue}
                    onChange={(e) =>
                      setAuthConfig({ ...authConfig, glossaryListValue: e.target.value })
                    }
                  >
                    {allGlossaryList?.map((glossaryListItem, id) => (
                      <MenuItem key={id} value={glossaryListItem.name}>
                        {glossaryListItem.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            <RHFTextField
              name="batchSize"
              label="Batch Size"
              value={batchSizeValue()}
              onChange={(e) => setAuthConfig({ ...authConfig, batchSize: e.target.value })}
            />
            <RHFTextField
              name="priority"
              label="Priority"
              type="number"
              value={initialAuthConfig.priority}
              // helperText={`Highest current priority: ${maxPriority}`}
            />
          </Box>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            type="button"
            variant="contained"
            onClick={onSubmit}
            loading={isSubmitting}
          >
            Create
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
