import { Button, Container } from '@mui/material';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import Iconify from 'src/components/iconify';
import { useAuthContext } from 'src/auth/hooks';
import { IOrganization } from 'src/types/organization';
import {
  doc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
  getDoc,
} from 'firebase/firestore';
import { useBoolean } from 'src/hooks/use-boolean';
import { useEffect, useState } from 'react';
import { useDashboardContext } from 'src/context/hooks';
import { LoadingScreen } from 'src/components/loading-screen';
import useFetcher, { endpoints } from 'src/utils/axios';
import ListView from './list-view-organisation';

export default function OrganisationListView() {
  const settings = useSettingsContext();
  const isLoading = useBoolean(true);
  const { allOrgs } = useDashboardContext();

  return (
    <>
      <CustomBreadcrumbs
        heading="Organisation"
        links={[{ name: '' }]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      {isLoading.value ? (
        <Container maxWidth={settings.themeStretch ? false : 'xl'}>
          <ListView allOrgs={allOrgs!} />
        </Container>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
}
