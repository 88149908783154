import { useEffect, useState } from 'react';
// @mui
import Container from '@mui/material/Container';

// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import useFetcher, { endpoints } from 'src/utils/axios';
import { IUserItem } from 'src/types/user';
import UserNewEditForm from '../user-new-edit-form';

// ----------------------------------------------------------------------

export default function UserEditView() {
  const settings = useSettingsContext();
  const [user, setUser] = useState<IUserItem>();
  const { fetchData } = useFetcher();
  useEffect(() => {
    fetchData(endpoints.users.getMe).then((res) => {
      setUser(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading="Profile"
        links={[{ name: user?.displayName }]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <UserNewEditForm currentUser={user} />
    </Container>
  );
}
