import { useState, useEffect } from 'react';
import useFetcher, { endpoints } from 'src/utils/axios';
import { GlossaryList } from 'src/types/glossary';
import { useDashboardContext } from 'src/context/hooks';

const useGlossaryList = () => {
  const [allGlossaryList, setAllGlossaryList] = useState<GlossaryList[]>([]);
  const { fetchData } = useFetcher();
  const { currentOrg } = useDashboardContext();

  useEffect(() => {
    const fetchGlossaryListData = () => {
      if (currentOrg) {
        fetchData([endpoints.glossary.getList, { params: { org_id: currentOrg.org_id } }]).then(
          (response: GlossaryList[]) => {
            setAllGlossaryList(response);
          }
        );
      }
    };

    fetchGlossaryListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg]);

  return allGlossaryList;
};

export default useGlossaryList;
