// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

// ----------------------------------------------------------------------

type Props = {
    open: boolean;
    onClose: VoidFunction;
    handleDelete: (id: string) => void;
    deleteitem: string;
};

export default function TranslateEngineDeleteForm({ handleDelete, open, onClose, deleteitem }: Props) {
    return (
        <Dialog
            fullWidth
            maxWidth={false}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: { maxWidth: 720 },
            }}
        >
            <DialogTitle>Delete</DialogTitle>

            <DialogContent>
                <Alert variant="outlined" severity="error" sx={{ mb: 3 }}>
                    Are you sure you want to Delete Translate Engine!
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>
                    Cancel
                </Button>

                <LoadingButton type="submit" variant="contained" onClick={() => { handleDelete(deleteitem); onClose() }}>
                    Delete Translate Engine
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
