import * as Yup from 'yup';
import { useEffect, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// _mock
// types
import { ILanugage } from 'src/types/translatable';
import { languages } from 'src/assets/data/languages';
import { IEnvironment } from 'src/types/orgCredential';
// assets
// components
import { useDashboardContext } from 'src/context/hooks';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import useFetcher, { endpoints } from 'src/utils/axios';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  environment: IEnvironment;
  handleEdit: (id: string) => void;
};

export default function EnvQuickEditForm({ environment, open, onClose, handleEdit }: Props) {
  const { updateData } = useFetcher();
  const { currentOrg } = useDashboardContext();
  const [languagesList, setLanguagesList] = useState<ILanugage[]>();
  const deployOptions = ['Deployable', 'Not Deployable'];
  const NewUserSchema = Yup.object().shape({
    env_name: Yup.string().required('Env Name is requireded'),
    api_name: Yup.string(),
    instance_url: Yup.string(),
    status: Yup.string(),
    isDeploying: Yup.boolean(),
    isRetrieving: Yup.boolean(),
    isTranslating: Yup.boolean(),
    refreshStatus: Yup.boolean(),
    isdeployable: Yup.boolean().required('deployable is requireded'),
  });

  const defaultValues = useMemo(
    () => ({
      env_name: environment?.name || '',
      api_name: environment?.api_name || '',
      instance_url: environment?.instance_url || '',
      status: environment?.status || '',
      isDeploying: environment?.is_deploying|| false,
      isRetrieving: environment?.is_retrieving || false,
      isTranslating: environment?.is_translating || false,
      refreshStatus: environment?.is_translating || false,
      isdeployable: environment?.isdeployable || false
    }),
    [environment]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });
  const {
    setValue,
    watch,
    handleSubmit,
    control
  } = methods;

  const editEnviroment = () => {
    handleEdit(environment?.env_id);
    onClose();
  };

  const onSubmit = handleSubmit(() => {
    try {
      const requestBody = {
        ...environment,
      };
      requestBody.name = watch().env_name;
      requestBody.isdeployable = watch().isdeployable

      updateData(endpoints.environment, requestBody).then(() => {
        onClose()
      });
      editEnviroment()
    } catch (error) {
      console.error(error);
    }
  });
  useEffect(() => {
    if (currentOrg && environment.languages) {
      const filteredLanguages = languages.filter((Ilang) =>
        environment.languages.includes(Ilang.isoCode)
      );
      setLanguagesList(filteredLanguages)
    } else {
      setLanguagesList([]);
    }
    }, [currentOrg, environment.languages]
  )
  const renderDeployField = (
    <Autocomplete
      fullWidth
      options={deployOptions}
      value={watch("isdeployable") ? "Deployable" : "Not Deployable"}
      onChange={(event, newValue) => {
        setValue('isdeployable', newValue === "Deployable");
      }}
      getOptionLabel={(option) => option}
      renderInput={(params) => <TextField {...params} label="Deployable Status" />}
      renderOption={(props, option) => (
        <li {...props} key={option}>
          {option}
        </li>
      )}
    />
  );  
  const renderLanguages = () => (
    <Autocomplete
      key={languagesList?.length}
      value={languagesList}
      options={languagesList || []}
      getOptionLabel={(option) => option.label}
      multiple
      renderOption={(props, option) => option.label}
      renderTags={(selected, getTagProps) =>
        selected.map((option, index) => (
          <Box component="span" {...getTagProps({ index })} sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${option.countryCode.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.countryCode.toLowerCase()}.png 2x`}
              alt={`${option.label} flag`}
              style={{ marginRight: 8 }}
            />
            {option.label}
          </Box>
        ))
      }
      renderInput={(params) => {
        if (languagesList === undefined || languagesList?.length > 0) {
          return (
            <TextField
              {...params}
              error
              label="Languages"
              inputProps={{
                ...params.inputProps,
              }}
            />
          );
        }
        return (
          <TextField
            {...params}
            label="No Languages available!"
            inputProps={{
              ...params.inputProps,
            }}
          />
        );
      }}
      disabled
      sx={{
        '& .MuiAutocomplete-popupIndicator': {
          display: 'none',
        },
      }}
    />
  );
  
  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720, maxHeight: '95%'},
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>Environment Update</DialogTitle>

        <DialogContent>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            sx={{ p: 2 }}
          >
            <RHFTextField name="instance_url" label="Instance URL" disabled />
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField name="api_name" label="API Name" disabled />
              <RHFTextField name="status" label="Status" disabled />
              <RHFTextField name="env_name" label="Environment Name" />
              {renderDeployField}
              <RHFTextField name="isDeploying" label="Is Deploying" disabled />
              <RHFTextField name="isRetrieving" label="Is Retrieving" disabled />
              <RHFTextField name="isTranslating" label="Is Translating" disabled />
              <RHFTextField name="refreshStatus" label="Refresh Status" disabled />
            </Box>
            {languagesList && <Box>{renderLanguages()}</Box>}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton variant="contained" onClick={onSubmit}>
            Update
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}