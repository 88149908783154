// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

// routes
import Router from 'src/routes/sections';
// theme
import ThemeProvider from 'src/theme';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import ProgressBar from 'src/components/progress-bar';
import MotionLazy from 'src/components/animate/motion-lazy';
import { SettingsDrawer, SettingsProvider } from 'src/components/settings';
// auth
import { AuthConsumer, AuthProvider } from 'src/auth/context/firebase';

import { useEffect } from 'react';

import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { SnackbarProvider } from './components/snackbar';
import { FIREBASE_API } from './config-global';

export default function App() {
  const charAt = `

  ░░░    ░░░ 
  ▒▒▒▒  ▒▒▒▒ 
  ▒▒ ▒▒▒▒ ▒▒ 
  ▓▓  ▓▓  ▓▓ 
  ██      ██ 
  
  `;

  // console.info(`%c${charAt}`, 'color: #5BE49B');
  const firebaseApp = initializeApp(FIREBASE_API);
  const analytics = getAnalytics(firebaseApp);
  logEvent(analytics, 'test_log');

  useScrollToTop();

  return (
    <AuthProvider>
      <SettingsProvider
        defaultSettings={{
          themeMode: 'light', // 'light' | 'dark'
          themeContrast: 'default', // 'default' | 'bold'
          themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
          themeColorPresets: 'blue', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
          themeStretch: true,
        }}
      >
        <ThemeProvider>
          <MotionLazy>
            <SnackbarProvider>
              <SettingsDrawer />
              <ProgressBar />
              <AuthConsumer>
                <Router />
              </AuthConsumer>
            </SnackbarProvider>
          </MotionLazy>
        </ThemeProvider>
      </SettingsProvider>
    </AuthProvider>
  );
}
