// TO GET THE USER FROM THE AUTHCONTEXT, YOU CAN USE

// CHANGE:
// import { useMockedUser } from 'src/hooks/use-mocked-user';
// const { user } = useMockedUser();

// TO:
// import { useAuthContext } from 'src/auth/hooks';
// const { user } = useAuthContext();

// ----------------------------------------------------------------------

export function useMockedUser() {
  const user = {
    id: '8864c717-587d-472a-929a-8e5f298024da-0',
    displayName: 'Simple Translate',
    email: 'accounting@simpletranslate.com',
    password: '',
    photoURL:
      'https://brnadic.com/____impro/1/onewebmedia/logo-print-hd-transparent.png?etag=%22468d2-5debd34a%22&sourceContentType=image%2Fpng&ignoreAspectRatio&resize=115%2B125',
    phoneNumber: '+4917620202020',
    country: 'Germany',
    address: 'Ballaufstr 7b',
    state: 'Bavaria',
    city: 'Karlsfled',
    zipCode: '85757',
    about: 'Praesent turpis. Phasellus viverra nulla ut metus varius laoreet. Phasellus tempus.',
    role: 'admin',
    isPublic: true,
  };

  return { user };
}
