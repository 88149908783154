import { sortBy } from 'lodash';
import { useAuthContext } from 'src/auth/hooks';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { useDashboardContext } from 'src/context/hooks';

import { RealTimeMessage } from 'src/types/realTimeMessages';
import { RealTimeContext } from './context';

type Props = {
  children: React.ReactNode;
};
function arraysEqual(arr1: any, arr2: any) {
  return JSON.stringify(arr1) === JSON.stringify(arr2);
}

export function RealTimeProvider({ children }: Props) {
  const { db } = useAuthContext();
  const { currentOrg } = useDashboardContext();
  const [orgId, setOrgId] = useState<string>();
  const [realTimeMessages, setRealTimeMessages] = useState<RealTimeMessage[]>([]);
  // const [updated, setUpdates] = useState<any>([]);

  useEffect(() => {
    if (currentOrg) {
      setOrgId(currentOrg.org_id);
    }
  }, [currentOrg]);

  useEffect(() => {
    if (orgId) {
      const q = query(collection(db, 'realTime'), where('org_id', '==', orgId));

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const notes: any = [];
        querySnapshot.forEach((doc) => {
          notes.push(doc.data());
        });
        if (!arraysEqual(notes, realTimeMessages)) {
          setRealTimeMessages(
            sortBy(
              notes,
              (item: any) => item.createdDate.seconds * 1000 + item.createdDate.nanoseconds / 1e6
            ).reverse()
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db, orgId]);

  const memorizedValue = useMemo(
    () => ({
      realTimeMessages,
    }),

    [realTimeMessages]
  );

  return <RealTimeContext.Provider value={memorizedValue}>{children}</RealTimeContext.Provider>;
}
