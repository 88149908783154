import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// _mock
// types
import { IOrgCredential } from 'src/types/orgCredential';
// assets
// components
import FormProvider, { RHFCheckbox, RHFTextField } from 'src/components/hook-form';
import useFetcher, { endpoints } from 'src/utils/axios';
import { useDashboardContext } from 'src/context/hooks';
import { Alert, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  credential?: IOrgCredential;
};

export default function EnvironmentQuickCreateForm({ credential, open, onClose }: Props) {
  const dashBoardContext = useDashboardContext();

  const [organizationType, setOrganizationType] = useState<any>(null);
  const { postData } = useFetcher();
  const [errorMessage, setErrorMessage] = useState<string>();
  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Org Name is required'),
    api_name: Yup.string()
      .matches(/^[a-zA-Z0-9_]+$/, {
        message: 'String must contain only letters, numbers, and underscores',
      })
      .required('This Value is required'),
    isdeployable: Yup.boolean().required('deployable is requireded'),
  });

  const defaultValues = useMemo(
    () => ({
      name: credential?.org_crediantial_name || '',
      api_name: credential?.api_name || '',
      isdeployable: false
    }),
    [credential]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    setValue,
    watch,
    reset,
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      createEnvironment(data.name, data.api_name, data.isdeployable);
      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
    } catch (error) {
      console.error(error);
    }
  });
  const createApiName = () => {
    const input = watch().name;
    const transformedString = input.replace(/[^\w\s]+/g, '').replace(/\s+/g, '_');
    setValue('api_name', transformedString);
  };

  const createEnvironment = (name: string, api_name: string | undefined, isdeployable: boolean) => {
    if (dashBoardContext.currentOrg) {
      const requestBody = {
        org_id: dashBoardContext.currentOrg.org_id,
        name,
        api_name,
        type: organizationType,
        refresh_status: 'active',
        isdeployable
      };
      postData(endpoints.environment, requestBody);
      onClose();
    }
  };
  useEffect(() => {
    const time = setTimeout(() => {
      createApiName();
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch().name]);
  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>Quick Create</DialogTitle>

        <DialogContent>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
            sx={{ p: 2 }}
          >
            <RHFTextField name="name" label="Name" />
            <RHFTextField name="api_name" label="API Name" />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                label="Type"
                onChange={(e) => setOrganizationType(e.target.value)}
              >
                <MenuItem value="production">Production</MenuItem>
                <MenuItem value="sandbox">Sandbox</MenuItem>
              </Select>
            </FormControl>

            <Controller
              name="isdeployable"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={({ target: { checked } }) => {
                        field.onChange(checked);
                      }}
                    />
                  }
                  label="Deployable"
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      ml: 0.5,
                    },
                  }}
                />
              )}
            />
          </Box>
          {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : null}
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Create
          </LoadingButton>
        </DialogActions>
      </FormProvider>

    </Dialog>
  );
}
