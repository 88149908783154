import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import SplashScreen from 'src/components/loading-screen/splash-screen';
import { DashBoardProvider } from 'src/context/dashboard/provider';
import { RealTimeProvider } from 'src/context/realtimeUpdates/provider';
// layouts
import MainLayout from 'src/layouts/main';
// support pages
const Contact = lazy(() => import('src/pages/support/contact'));
const Faqs = lazy(() => import('src/pages/support/faqs'));

export const supportRoutes = [
  {
    path: 'support',
    element: (
      <DashBoardProvider>
        <RealTimeProvider>
          <MainLayout>
            <Suspense fallback={<SplashScreen />}>
              <Outlet />
            </Suspense>
          </MainLayout>
        </RealTimeProvider>
      </DashBoardProvider>
    ),
    children: [
      { path: 'contact-us', element: <Contact />, },
      { path: 'faqs', element: <Faqs />, },
    ],
  },
];
