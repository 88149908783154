import { Alert, Box, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { IEnvironment } from 'src/types/orgCredential';

type Props = {
  environemt: IEnvironment | undefined;
};

export default function AlertComponent({ environemt }: Props) {
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    if (environemt?.is_deploying) {
      setMessage(`deployment in progress for ${environemt?.name}`);
    } else if (environemt?.is_translating) {
      setMessage(`translation in progress for ${environemt?.name}`);
    } else if (environemt?.is_retrieving) {
      setMessage(`retrieve in progress for ${environemt?.name}`);
    } else {
      setMessage('');
    }
  }, [environemt]);
  return (
    <>
      {message !== '' ? (
        <Box p={1} sx={{ maxWidth: '500px', marginRight: 'auto' }}>
          <Alert
            variant="outlined"
            severity="warning"
            icon={
              <Box sx={{ display: 'flex' }}>
                <CircularProgress color="inherit" size={20} />
              </Box>
            }
          >
            {message}
          </Alert>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
}
