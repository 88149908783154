import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { TableNoData } from 'src/components/table';
import { ILanugage, TranslationEngineWithLanguageMapping } from 'src/types/translatable';
import {
  Card,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import Scrollbar from 'src/components/scrollbar';
import EmptyContent from 'src/components/empty-content';
import LanguageSelectionCard from './language-selection-card';

interface LanguageMappingDashboardProps {
  settings: {
    themeStretch: boolean;
  };
  languageOpener: {
    value: boolean;
    onTrue: () => void;
    onFalse: () => void;
  };
  mappedLanguages: {
    label: string;
    countryCode: string;
    isoCode: string;
  }[];
  selectedLanguage: ILanugage | undefined;
  setSelectedLanguage: any;
  selectedMasterLanguageEngine: TranslationEngineWithLanguageMapping[] | undefined;
  columns: GridColDef[];
  notFound: boolean;
}

function LanguageMappingDashboard({
  settings,
  languageOpener,
  mappedLanguages,
  selectedLanguage,
  setSelectedLanguage,
  selectedMasterLanguageEngine,
  columns,
  notFound,
}: LanguageMappingDashboardProps) {
  return (
    <>
      <LanguageSelectionCard
        languageOpener={languageOpener}
        mappedLanguages={mappedLanguages}
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
      />

      {selectedLanguage ? (
        <Card
          sx={{
            m: 1,
            p: 2,
          }}
        >
          <DataGrid
            rows={selectedMasterLanguageEngine || []}
            columns={columns}
            rowHeight={100}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
          />
        </Card>
      ) : (
        <Card
          sx={{
            m: 1,
            p: 2,
            overflow: 'unset',
          }}
        >
          <TableCell colSpan={12} sx={{ width: '1%', padding: 0 }}>
            <EmptyContent
              filled
              title="No Data"
              sx={{
                py: 10,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            />
          </TableCell>
        </Card>
      )}
    </>
  );
}

export default LanguageMappingDashboard;
