import { useState, useCallback } from 'react';
// @mui
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Container from '@mui/material/Container';
// routes

// components
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import UserEditView from './user-edit-view';
import CompanySecurity from '../user-security';
import OrganisationListView from '../user-organisations-table/user-organisations-table';

// ----------------------------------------------------------------------

const TABS = [
  {
    value: 'profile',
    label: 'Profile',
    icon: <Iconify icon="clarity:user-solid" width={21} />,
  },
  {
    value: 'security',
    label: 'Security',
    icon: <Iconify icon="wpf:key-security" width={18} />,
  },
  {
    value: 'organisation',
    label: 'Organisation',
    icon: <Iconify icon="solar:home-bold-duotone" width={18} />,
  },

];

// ----------------------------------------------------------------------

export default function ProfileView() {
  const settings = useSettingsContext();

  const [currentTab, setCurrentTab] = useState('profile');

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <Tabs
        value={currentTab}
        onChange={handleChangeTab}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        {TABS.map((tab) => (
          <Tab key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
        ))}
      </Tabs>

      {currentTab === 'profile' && <UserEditView />}

      {currentTab === 'security' && <CompanySecurity />}
      {currentTab === 'organisation' && <OrganisationListView />}
    </Container>
  );
}
