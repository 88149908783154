// @mui
import { Theme, SxProps } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
//
import { Box } from '@mui/material';
import EmptyContent from '../empty-content';

// ----------------------------------------------------------------------

type Props = {
  notFound: boolean;
  sx?: SxProps<Theme>;
};

export default function PermissionDenied({ notFound, sx }: Props) {
  return (
    <Box
      sx={{
        px: 5,
        width: 1,
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
    >
      <TableRow>
        {notFound ? (
          <TableCell colSpan={12} sx={{ width: '1%', padding: 0 }}>
            <EmptyContent
              filled
              title="Permission Denied"
              sx={{
                py: 10,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                ...sx,
              }}
            />
          </TableCell>
        ) : (
          <TableCell colSpan={12} sx={{ p: 0 }} />
        )}
      </TableRow>
    </Box>
  );
}
