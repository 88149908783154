import { useCallback } from 'react';
// @mui

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
// types
import { ITableFilters, IOrderTableFilterValue } from 'src/types/translatable';
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

type Props = {
  filters: ITableFilters;
  onFilters: (name: string, value: IOrderTableFilterValue) => void;
  //
  canReset: boolean;
  onResetFilters: VoidFunction;
};

export default function TableToolbar({
  filters,
  onFilters,
  canReset,
  onResetFilters
}: Props) {
  const handleFilterName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilters('name', event.target.value);
    },
    [onFilters]
  );

  return (
    <Stack direction="row" alignItems="center" spacing={0} flexGrow={1} sx={{ width: 1, pb: 2 }}>
      <TextField
        fullWidth
        value={filters.name}
        onChange={handleFilterName}
        placeholder="Search metadata"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}
