import { useEffect, useState } from 'react';
import { m } from 'framer-motion';
//
import { useTheme, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import { Link, Typography } from '@mui/material';
// routes
import { paths } from 'src/routes/paths';
//
import { IEnvironment } from 'src/types/orgCredential';
//
import { varFade } from 'src/components/animate';
import useFetcher, { endpoints } from 'src/utils/axios';
import { useDashboardContext, useRealTimeContext } from 'src/context/hooks';
import { RouterLink } from 'src/routes/components';
import CredentialsView from '../credentials/view/view';
import TranslationEngineView from '../translationEngine/view';
import AlertComponent from '../newMetadataTranslator/view/mt-table/translatables/alert-component';

type Props = {
  open: boolean;
  onClose: VoidFunction;
  wizzardStatus: string;
  wizzardCompleted: VoidFunction;
};

const steps = [
  'Create Environment and Credential',
  'Retrieve Metadata',
  'Enable/Disable Translation Engines',
];
const stepStatusMap: Record<string, number> = {
  credentialSetup: 0,
  retrieveTranslation: 1,
  translationEngine: 2,
};

export default function WizzardDialog({ open, onClose, wizzardStatus, wizzardCompleted }: Props) {
  const { postData, fetchData } = useFetcher();
  const { currentOrg } = useDashboardContext();
  const [activeStep, setActiveStep] = useState(0);
  const [environments, setEnvironments] = useState<IEnvironment[]>([]);
  const { realTimeMessages } = useRealTimeContext();
  const theme = useTheme();

  useEffect(() => {
    setActiveStep(stepStatusMap[wizzardStatus] || 0);
  }, [wizzardStatus]);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    saveWizzardStatus(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    saveWizzardStatus(activeStep - 1);
  };

  const saveWizzardStatus = async (step: number) => {
    const currentStepStatus = Object.keys(stepStatusMap).find((key) => stepStatusMap[key] === step);
    if (currentStepStatus && currentOrg?.org_id) {
      postData(endpoints.org.setWizzardSetupStatus, {
        org_id: currentOrg.org_id,
        wizzardStatus: currentStepStatus,
      });
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    saveWizzardStatus(0);
  };

  const handleFinish = () => {
    const currentStepStatus = 'completed';
    if (currentOrg?.org_id) {
      postData(endpoints.org.setWizzardSetupStatus, {
        org_id: currentOrg.org_id,
        wizzardStatus: currentStepStatus,
      });
    }
    wizzardCompleted();
    onClose();
  };

  useEffect(() => {
    if (realTimeMessages && currentOrg) {
      if (realTimeMessages[0]?.type === 'environment_refresh') {
        fetchEnv();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realTimeMessages]);

  const fetchEnv = () => {
    fetchData(endpoints.environment).then((env) => {
      setEnvironments(env);
    });
  };

  const stepContent = [
    <CredentialsView key="step1" wizzardSetupType="envAndCredential" />,
    <CredentialsView key="step2" wizzardSetupType="retrieveTranslation" />,
    <TranslationEngineView key="step3" wizzardSetupType="translationEngine" />,
  ];
  const renderImg = (
    <Stack component={m.div} variants={varFade().inUp} alignItems="center">
      <Box
        component={m.img}
        animate={{
          y: [-20, 0, -20],
        }}
        transition={{ duration: 4, repeat: Infinity }}
        alt="rocket"
        src="/assets/illustrations/characters/character_2.png"
        sx={{
          maxWidth: 180,
          p: 5,
        }}
      />
    </Stack>
  );

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: activeStep === steps.length ? '50%' : '90%', maxHeight: '90%' },
      }}
    >
      <Box sx={{ p: 5 }}>
        {activeStep === steps.length ? (
          // finish step
          <>
            <Stack
              alignItems="center"
              justifyContent="center"
              direction={{ xs: 'column', md: 'row' }}
              sx={{
                borderRadius: 2,
                pb: { xs: 5, md: 0 },
              }}
            >
              <Box sx={{ pr: 5 }}>
                <Typography variant="h2">You are ready to translate!</Typography>
                <Paper
                  sx={{
                    p: 3,
                    my: 3,
                    bgcolor: alpha(theme.palette.grey[500], 0.12),
                  }}
                >
                  <ul style={{ lineHeight: '1.8em' }}>
                    <li>
                      Congrats, you have set up everything needed to start <br /> working in a
                      simple way.
                    </li>
                    <li>You will be notified when the retrieve metadata is complete.</li>
                    <li>
                      Learn more:{' '}
                      <Link href={paths.docs.overview}>Simple Translate Documentation</Link>
                    </li>
                  </ul>
                </Paper>
                {environments.map((env) => (
                  <AlertComponent environemt={env} />
                ))}
              </Box>
              {renderImg}
            </Stack>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ flexGrow: 1 }} />
              <Button variant="contained" onClick={handleReset} sx={{ mr: 1 }}>
                Try Again
              </Button>
              <Button
                variant="contained"
                component={RouterLink}
                href={paths.dashboard.root}
                onClick={handleFinish}
              >
                Finish
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Paper
              sx={{
                p: 3,
                my: 3,
                minHeight: 120,
                bgcolor: alpha(theme.palette.grey[500], 0.12),
              }}
            >
              {stepContent[activeStep]}
            </Paper>

            <Box sx={{ display: 'flex' }}>
              <Button
                variant="outlined"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              {!(activeStep === steps.length) && (
                <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>
                  Close
                </Button>
              )}
              <Button onClick={handleNext} variant="contained">
                Next
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Dialog>
  );
}
