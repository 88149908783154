// @mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import { enqueueSnackbar } from 'src/components/snackbar';

// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import TextField from '@mui/material/TextField';
import { IOrganization } from 'src/types/organization';
import { IUser } from 'src/types/user';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Switch from '@mui/material/Switch';
import { useEffect, useState } from 'react';
import { useAuthContext } from 'src/auth/hooks';
import { is } from 'date-fns/locale';

type Props = {
  row: IOrganization;
  setDefaultValue: (orgId: string) => void;
  user: IUser | null;
};

export default function TableRowMetadata({ row, setDefaultValue, user }: Props) {
  const [localRow, setLocalRow] = useState(row);
  const isDefault = useBoolean(false);
  useEffect(() => {
    if (user?.org_id === row.org_id) {
      isDefault.onTrue();
    } else {
      isDefault.onFalse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  const handleToggleDefault = () => {
    setDefaultValue(row.org_id);
  };
  const handleCopy = (value: string) => {
    navigator.clipboard.writeText(value);
  };

  const renderPrimary = (
    <TableRow hover selected={isDefault.value}>
      <TableCell sx={{ width: '50%' }}>
        <TextField name="name" value={localRow.displayName} fullWidth />
      </TableCell>
      <TableCell sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField name="org_id" value={localRow.org_id} fullWidth />
        <IconButton onClick={() => handleCopy(row.org_id)} sx={{ marginLeft: 1 }}>
          <ContentCopyIcon />
        </IconButton>
      </TableCell>
      <TableCell padding="checkbox" sx={{ width: '10%' }}>
        <Switch checked={isDefault.value} onChange={handleToggleDefault} color="primary" />
      </TableCell>
    </TableRow>
  );

  return <>{renderPrimary}</>;
}
