import { useCallback, useEffect } from 'react';
// routes
import { paths } from 'src/routes/paths';
import { useRouter, useSearchParams } from 'src/routes/hook';
//
import { useLocation } from 'react-router-dom';
import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function GuestGuard({ children }: Props) {
  // ...

  const router = useRouter();

  const searchParams = useSearchParams();

  const returnTo = searchParams.get('returnTo') || paths.dashboard.root;
  const params = searchParams.get('params');
  const cleanedParamsString = params?.replace(/^\?/, '');
  const { authenticated } = useAuthContext();

  const check = useCallback(() => {
    if (authenticated) {
      router.replace(returnTo, cleanedParamsString || '');
    }
  }, [authenticated, returnTo, router, cleanedParamsString]);

  useEffect(() => {
    check();
  }, [check]);

  return <>{children}</>;
}
