// ----------------------------------------------------------------------

export type IOrgCredentialTableFilterValue = string | null;

export type ITableFilters = {
  name: string;
  status: string;
  startDate: Date | null;
  endDate: Date | null;
};

// ----------------------------------------------------------------------

export type IOrgCredential = {
  api_name: string;
  credentials_id: string;
  org_id: string;
  env_id: string;
  org_crediantial_name: string;
  instance_url: string;
  status: string;
  languages: string[];
  last_metadata_refresh: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  createdBy: string;
  cred_state: string;
  login_url: string;
  type: string;
};
export type IOrgCredentialRow = {
  api_name: string;
  credentials_id: string;
  org_id: string;
  env_id: string;
  org_crediantial_name: string;
  instance_url: string;
  status: string;
  env_name: string;
  env_api_name: string;
  last_metadata_refresh: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
  createdBy: string;
  cred_state: string;
  login_url: string;
  type: string;
};
export type IEnvironment = {
  env_id: string;
  org_id: string;
  instance_url: string;
  name: string;
  api_name: string;
  type: string;
  status: string;
  languages: string[];
  refresh_status: string | null; // in_progress, ready
  isdeployable: boolean;
  is_translating: boolean;
  is_deploying: boolean;
  is_retrieving: boolean;
};
export const ENVIRONMENT_STATUS_OPTIONS = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
];
