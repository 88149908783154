// LanguageSelectionCard.js
import { Card, Grid, TextField, Autocomplete, Box } from '@mui/material';
import { ILanugage } from 'src/types/translatable';

interface LanguageSelectionCardProps {
  languageOpener: {
    value: boolean;
    onTrue: () => void;
    onFalse: () => void;
  };
  mappedLanguages: {
    label: string;
    countryCode: string;
    isoCode: string;
  }[];
  selectedLanguage: ILanugage | undefined;
  setSelectedLanguage: (
    newLanguage: { label: string; countryCode: string; isoCode: string } | null
  ) => void;
}

export default function LanguageSelectionCard({
  languageOpener,
  mappedLanguages,
  selectedLanguage,
  setSelectedLanguage,
}: LanguageSelectionCardProps) {
  return (
    <Card sx={{ m: 1, p: 2 }}>
      <Grid item xs={12} xl={12} justifyContent="flex-end" p={3}>
        <Autocomplete
          open={languageOpener.value}
          onOpen={languageOpener.onTrue}
          onClose={(event, reason) => {
            if (reason !== 'selectOption' && reason !== 'removeOption') {
              languageOpener.onFalse();
            }
          }}
          id="country-select"
          options={mappedLanguages}
          autoHighlight
          value={selectedLanguage}
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.countryCode.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.countryCode.toLowerCase()}.png 2x`}
                alt=""
              />
              {option.label} ({option.isoCode})
            </Box>
          )}
          onChange={(event, newValue) => {
            setSelectedLanguage(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Choose Master Language"
              inputProps={{ ...params.inputProps }}
              error={mappedLanguages.length === 0}
            />
          )}
        />
      </Grid>
    </Card>
  );
}
