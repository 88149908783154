import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

const icon = (name: string) => <Iconify icon={name} />;

const ICONS = {
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('mdi:account'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  salesforce: icon('mdi:salesforce'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  library: icon('mdi:library'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  job: icon('iconamoon:synchronize-duotone'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  account: icon('solar:home-bold-duotone'),
  credential: icon('mdi:cloud-key'),
  approval: icon('wpf:approval'),
  security: icon('wpf:key-security'),
  dict: icon('streamline:dictionary-language-book-solid'),
  translation: icon('dashicons:translation'),
  support: icon('fa:paper-plane'),
  overview: icon('jam:document-f'),
};

// ----------------------------------------------------------------------

export function useDocsOrderData() {
  // eslint-disable-next-line arrow-body-style
  const managementItems = useMemo(() => {
    return [
      {
        title: 'Overview',
        path: paths.docs.overview,
        icon: ICONS.overview,
      },
      {
        title: 'Translation Workbench',
        path: paths.docs.translationWorkbench.root,
        icon: ICONS.translation,
        children: [
          {
            title: 'Retrieve Metadata',
            path: paths.docs.translationWorkbench.retrieveMetadata,
            icon: ICONS.blank,
          },
          {
            title: 'Filter Metadata',
            path: paths.docs.translationWorkbench.filterMetadata,
            icon: ICONS.blank,
          },
          {
            title: 'Translate Metadata',
            path: paths.docs.translationWorkbench.translateMetadata,
            icon: ICONS.blank,
          },
          {
            title: 'Deploy Metadata',
            path: paths.docs.translationWorkbench.deployMetadata,
            icon: ICONS.blank,
          },
          {
            title: 'Limit',
            path: paths.docs.translationWorkbench.limit,
            icon: ICONS.blank,
          },
          {
            title: 'Token History',
            path: paths.docs.STSetup.tokenHistory,
            icon: ICONS.blank,
          },
        ],
      },
      {
        title: 'Setup',
        path: paths.docs.STSetup.root,
        icon: ICONS.dict,
        children: [
          {
            title: 'Org Credentials',
            path: paths.docs.STSetup.orgCredentials,
            icon: ICONS.blank,
          },
          {
            title: 'Translation Engine',
            path: paths.docs.STSetup.translationEngine,
            icon: ICONS.blank,
          },
          // {
          //   title: 'Approval',
          //   path: paths.docs.STSetup.setupApproval,
          //   icon: ICONS.blank,
          // },
          {
            title: 'Glossary',
            path: paths.docs.STSetup.glossary,
            icon: ICONS.blank,
          },
          {
            title: 'Notifications',
            path: paths.docs.STSetup.notifications,
            icon: ICONS.blank,
          },
        ],
      },
      {
        title: 'Meta Translate Job',
        path: paths.docs.MTJob.root,
        icon: ICONS.job,
        children: [
          {
            title: 'Create/Update Job',
            path: paths.docs.MTJob.createUpdateJob,
            icon: ICONS.blank,
          },
          {
            title: 'Compare Orgs',
            path: paths.docs.MTJob.compareOrgs,
            icon: ICONS.blank,
          },
          {
            title: 'Orgs Deployment',
            path: paths.docs.MTJob.orgsDeployment,
            icon: ICONS.blank,
          },
          {
            title: 'Status',
            path: paths.docs.MTJob.status,
            icon: ICONS.blank,
          },
        ],
      },
      {
        title: 'Salesforce',
        path: paths.docs.salesforce.root,
        icon: ICONS.salesforce,
        children: [
          {
            title: 'Install a Package',
            path: paths.docs.salesforce.installPackage,
            icon: ICONS.blank,
          },
          {
            title: 'Authentication and Setup',
            path: paths.docs.salesforce.authenticationSalesforce,
            icon: ICONS.blank,
          },
          {
            title: 'RealTime Data Translation',
            path: paths.docs.salesforce.realTimeDataTranslation,
            icon: ICONS.blank,
          },
          {
            title: 'Language Detection',
            path: paths.docs.salesforce.languageDetection,
            icon: ICONS.blank,
          },
          {
            title: 'Case Translation',
            path: paths.docs.salesforce.caseTranslation,
            icon: ICONS.blank,
          },
          {
            title: 'FeedItem Post Translation',
            path: paths.docs.salesforce.feedItemPostTranslation,
            icon: ICONS.blank,
          },
          {
            title: 'Email Translation',
            path: paths.docs.salesforce.emailTranslation,
            icon: ICONS.blank,
          },
          {
            title: 'Edit Record Page',
            path: paths.docs.salesforce.editRecordPage,
            icon: ICONS.blank,
          },
        ],
      },
      {
        title: 'Open APIs',
        path: paths.docs.openAPIs.root,
        icon: ICONS.security,
        children: [
          {
            title: 'Token',
            path: paths.docs.openAPIs.token,
            icon: ICONS.blank,
          },
        ],
      },
      {
        title: 'Extensions',
        path: paths.docs.extensions.root,
        icon: ICONS.library,
        children: [
          {
            title: 'Copado CI/CD',
            path: paths.docs.extensions.copado,
            icon: ICONS.blank,
          },
        ],
      },
      {
        title: 'User',
        path: paths.docs.user.root,
        icon: ICONS.user,
        children: [
          {
            title: 'Register User',
            path: paths.docs.user.register,
            icon: ICONS.blank,
          },
          {
            title: 'Invite User',
            path: paths.docs.user.invite,
            icon: ICONS.blank,
          },
          {
            title: 'User Roles',
            path: paths.docs.user.roles,
            icon: ICONS.blank,
          },
          {
            title: 'Password Reset',
            path: paths.docs.user.passwordReset,
            icon: ICONS.blank,
          },
        ],
      },
      {
        title: 'Support',
        path: '#',
        icon: ICONS.support,
        children: [
          {
            title: 'Contact',
            path: paths.supportPath.contact,
            icon: ICONS.blank,
            externalLink: true,
          },
          {
            title: 'FAQs',
            path: paths.supportPath.faqs,
            icon: ICONS.blank,
            externalLink: true,
          },
        ],
      },
    ];
  }, []);

  const data = useMemo(
    () => [
      {
        subheader: 'Need Help?',
        items: managementItems,
      },
    ],
    [managementItems]
  );

  return data;
}
