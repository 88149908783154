import { useCallback, useState } from 'react';

// ----------------------------------------------------------------------

type ReturnType = {
  onClose: VoidFunction;
  open: HTMLElement | null;
  onOpen: (event: React.MouseEvent<HTMLElement>) => void;
  setOpen: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  // value: any;
  // setValue: React.Dispatch<React.SetStateAction<any>>;
};

export default function usePopover(): ReturnType {
  const [open, setOpen] = useState<HTMLElement | null>(null);
  // const [value, setValue] = useState<HTMLElement | null>(null);
  const onOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  }, []);

  const onClose = useCallback(() => {
    setOpen(null);
  }, []);

  return {
    // value,
    // setValue,
    open,
    onOpen,
    onClose,
    setOpen,
  };
}
