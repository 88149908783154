// @mui
import Box from '@mui/material/Box';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
// components
import { ITreeView } from 'src/types/translatable';

// ----------------------------------------------------------------------

type Props = {
  row: ITreeView;
  selected: boolean;
  onSelectRow: VoidFunction;
  disabled?: boolean;
};

export default function RetrieveTableRow({ row, selected, onSelectRow, disabled }: Props) {
  const { metadata_type_label } = row;

  const renderPrimary = (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} disabled={disabled} />
      </TableCell>
      <TableCell>
        <Box>{metadata_type_label}</Box>
      </TableCell>
    </TableRow>
  );

  return <>{renderPrimary}</>;
}
