import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

export function useRouter() {
  const navigate = useNavigate();

  const router = useMemo(
    () => ({
      back: () => navigate(-1),
      forward: () => navigate(1),
      reload: () => window.location.reload(),
      push: (pathName: string, search: string = '') => {
        navigate(`${pathName}?${search}`);
      },
      replace: (pathName: string, search: string = '') => {
        navigate(`${pathName}${search}`, { replace: true });
      },
    }),
    [navigate]
  );

  return router;
}
