import { ILanugage } from '../../types/translatable';

export const languages: ILanugage[] = [
  { label: 'Afrikaans', countryCode: 'ZA', isoCode: 'af' },
  { label: 'Amharic', countryCode: 'ET', isoCode: 'am' },
  { label: 'Arabic (Algeria)', countryCode: 'DZ', isoCode: 'ar_DZ' },
  { label: 'Arabic (Bahrain)', countryCode: 'BH', isoCode: 'ar_BH' },
  { label: 'Arabic (Egypt)', countryCode: 'EG', isoCode: 'ar_EG' },
  { label: 'Arabic (Iraq)', countryCode: 'IQ', isoCode: 'ar_IQ' },
  { label: 'Arabic (Jordan)', countryCode: 'JO', isoCode: 'ar_JO' },
  { label: 'Arabic (Kuwait)', countryCode: 'KW', isoCode: 'ar_KW' },
  { label: 'Arabic (Lebanon)', countryCode: 'LB', isoCode: 'ar_LB' },
  { label: 'Arabic (Libya)', countryCode: 'LY', isoCode: 'ar_LY' },
  { label: 'Arabic (Morocco)', countryCode: 'MA', isoCode: 'ar_MA' },
  { label: 'Arabic (Oman)', countryCode: 'OM', isoCode: 'ar_OM' },
  { label: 'Arabic (Qatar)', countryCode: 'QA', isoCode: 'ar_QA' },
  { label: 'Arabic (Saudi Arabia)', countryCode: 'SA', isoCode: 'ar_SA' },
  { label: 'Arabic (Sudan)', countryCode: 'SD', isoCode: 'ar_SD' },
  { label: 'Arabic (Syria)', countryCode: 'SY', isoCode: 'ar_SY' },
  { label: 'Arabic (Tunisia)', countryCode: 'TN', isoCode: 'ar_TN' },
  { label: 'Arabic (United Arab Emirates)', countryCode: 'AE', isoCode: 'ar_AE' },
  { label: 'Arabic (Yemen)', countryCode: 'YE', isoCode: 'ar_YE' },
  { label: 'Armenian', countryCode: 'AM', isoCode: 'hy' },
  { label: 'Basque', countryCode: 'ES', isoCode: 'eu' },
  { label: 'Bosnian', countryCode: 'BA', isoCode: 'bs' },
  { label: 'Bengali', countryCode: 'BD', isoCode: 'bn' },
  { label: 'Burmese', countryCode: 'MM', isoCode: 'my' },
  { label: 'Catalan', countryCode: 'ES', isoCode: 'ca' },
  { label: 'Chinese (Hong Kong)', countryCode: 'HK', isoCode: 'zh_HK' },
  { label: 'Chinese (Singapore)', countryCode: 'SG', isoCode: 'zh_SG' },
  { label: 'Chinese (Malaysia)', countryCode: 'MY', isoCode: 'zh_MY' },
  { label: 'Dutch (Belgium)', countryCode: 'BE', isoCode: 'nl_BE' },
  { label: 'English (Australia)', countryCode: 'AU', isoCode: 'en_AU' },
  { label: 'English (Belgium)', countryCode: 'BE', isoCode: 'en_BE' },
  { label: 'English (Canada)', countryCode: 'CA', isoCode: 'en_CA' },
  { label: 'English (Cyprus)', countryCode: 'CY', isoCode: 'en_CY' },
  { label: 'English (Germany)', countryCode: 'DE', isoCode: 'en_DE' },
  { label: 'English (Hong Kong)', countryCode: 'HK', isoCode: 'en_HK' },
  { label: 'English (India)', countryCode: 'IN', isoCode: 'en_IN' },
  { label: 'English (Ireland)', countryCode: 'IE', isoCode: 'en_IE' },
  { label: 'English (Israel)', countryCode: 'IL', isoCode: 'en_IL' },
  { label: 'English (Malaysia)', countryCode: 'MY', isoCode: 'en_MY' },
  { label: 'English (Malta)', countryCode: 'MT', isoCode: 'en_MT' },
  { label: 'English (Netherlands)', countryCode: 'NL', isoCode: 'en_NL' },
  { label: 'English (New Zealand)', countryCode: 'NZ', isoCode: 'en_NZ' },
  { label: 'English (Philippines)', countryCode: 'PH', isoCode: 'en_PH' },
  { label: 'English (Singapore)', countryCode: 'SG', isoCode: 'en_SG' },
  { label: 'English (South Africa)', countryCode: 'ZA', isoCode: 'en_ZA' },
  { label: 'English (United Arab Emirates)', countryCode: 'AE', isoCode: 'en_AE' },
  { label: 'Estonian', countryCode: 'EE', isoCode: 'et' },
  { label: 'Farsi', countryCode: 'IR', isoCode: 'fa' },
  { label: 'French (Belgium)', countryCode: 'BE', isoCode: 'fr_BE' },
  { label: 'French (Canada)', countryCode: 'CA', isoCode: 'fr_CA' },
  { label: 'French (Luxembourg)', countryCode: 'LU', isoCode: 'fr_LU' },
  { label: 'French (Morocco)', countryCode: 'MA', isoCode: 'fr_MA' },
  { label: 'French (Switzerland)', countryCode: 'CH', isoCode: 'fr_CH' },
  { label: 'Georgian', countryCode: 'GE', isoCode: 'ka' },
  { label: 'German (Austria)', countryCode: 'AT', isoCode: 'de_AT' },
  { label: 'German (Belgium)', countryCode: 'BE', isoCode: 'de_BE' },
  { label: 'German (Luxembourg)', countryCode: 'LU', isoCode: 'de_LU' },
  { label: 'German (Switzerland)', countryCode: 'CH', isoCode: 'de_CH' },
  { label: 'Greek (Cyprus)', countryCode: 'CY', isoCode: 'el_CY' },
  { label: 'Greenlandic', countryCode: 'GL', isoCode: 'kl' },
  { label: 'Gujarati', countryCode: 'IN', isoCode: 'gu' },
  { label: 'Hawaiian', countryCode: 'US', isoCode: 'haw' },
  { label: 'Haitian Creole', countryCode: 'HT', isoCode: 'ht' },
  { label: 'Hindi', countryCode: 'IN', isoCode: 'hi' },
  { label: 'Hmong', countryCode: 'CN', isoCode: 'hmn' },
  { label: 'Icelandic', countryCode: 'IS', isoCode: 'is' },
  { label: 'Irish', countryCode: 'IE', isoCode: 'ga' },
  { label: 'Italian (Switzerland)', countryCode: 'CH', isoCode: 'it_CH' },
  { label: 'Kannada', countryCode: 'IN', isoCode: 'kn' },
  { label: 'Kazakh', countryCode: 'KZ', isoCode: 'kk' },
  { label: 'Khmer', countryCode: 'KH', isoCode: 'km' },
  { label: 'Latvian', countryCode: 'LV', isoCode: 'lv' },
  { label: 'Lithuanian', countryCode: 'LT', isoCode: 'lt' },
  { label: 'Luxembourgish', countryCode: 'LU', isoCode: 'lb' },
  { label: 'Macedonian', countryCode: 'MK', isoCode: 'mk' },
  { label: 'Malay', countryCode: 'MY', isoCode: 'ms' },
  { label: 'Malayalam', countryCode: 'IN', isoCode: 'ml' },
  { label: 'Maltese', countryCode: 'MT', isoCode: 'mt' },
  { label: 'Marathi', countryCode: 'IN', isoCode: 'mr' },
  { label: 'Montenegrin', countryCode: 'ME', isoCode: 'sh_ME' },
  { label: 'Punjabi', countryCode: 'IN', isoCode: 'pa' },
  { label: 'Romanian (Moldova)', countryCode: 'MD', isoCode: 'ro_MD' },
  { label: 'Romansh', countryCode: 'CH', isoCode: 'rm' },
  { label: 'Russian (Armenia)', countryCode: 'AM', isoCode: 'ru_AM' },
  { label: 'Russian (Belarus)', countryCode: 'BY', isoCode: 'ru_BY' },
  { label: 'Russian (Kazakhstan)', countryCode: 'KZ', isoCode: 'ru_KZ' },
  { label: 'Russian (Kyrgyzstan)', countryCode: 'KG', isoCode: 'ru_KG' },
  { label: 'Russian (Lithuania)', countryCode: 'LT', isoCode: 'ru_LT' },
  { label: 'Russian (Moldova)', countryCode: 'MD', isoCode: 'ru_MD' },
  { label: 'Russian (Poland)', countryCode: 'PL', isoCode: 'ru_PL' },
  { label: 'Russian (Ukraine)', countryCode: 'UA', isoCode: 'ru_UA' },
  { label: 'Samoan', countryCode: 'WS', isoCode: 'sm' },
  { label: 'Serbian (Cyrillic)', countryCode: 'RS', isoCode: 'sr' },
  { label: 'Serbian (Latin)', countryCode: 'RS', isoCode: 'sh' },
  { label: 'Spanish (Argentina)', countryCode: 'AR', isoCode: 'es_AR' },
  { label: 'Spanish (Bolivia)', countryCode: 'BO', isoCode: 'es_BO' },
  { label: 'Spanish (Chile)', countryCode: 'CL', isoCode: 'es_CL' },
  { label: 'Spanish (Colombia)', countryCode: 'CO', isoCode: 'es_CO' },
  { label: 'Spanish (Costa Rica)', countryCode: 'CR', isoCode: 'es_CR' },
  { label: 'Spanish (Dominican Republic)', countryCode: 'DO', isoCode: 'es_DO' },
  { label: 'Spanish (Ecuador)', countryCode: 'EC', isoCode: 'es_EC' },
  { label: 'Spanish (El Salvador)', countryCode: 'SV', isoCode: 'es_SV' },
  { label: 'Spanish (Guatemala)', countryCode: 'GT', isoCode: 'es_GT' },
  { label: 'Spanish (Honduras)', countryCode: 'HN', isoCode: 'es_HN' },
  { label: 'Spanish (Nicaragua)', countryCode: 'NI', isoCode: 'es_NI' },
  { label: 'Spanish (Panama)', countryCode: 'PA', isoCode: 'es_PA' },
  { label: 'Spanish (Paraguay)', countryCode: 'PY', isoCode: 'es_PY' },
  { label: 'Spanish (Peru)', countryCode: 'PE', isoCode: 'es_PE' },
  { label: 'Spanish (Puerto Rico)', countryCode: 'PR', isoCode: 'es_PR' },
  { label: 'Spanish (United States)', countryCode: 'US', isoCode: 'es_US' },
  { label: 'Spanish (Uruguay)', countryCode: 'UY', isoCode: 'es_UY' },
  { label: 'Spanish (Venezuela)', countryCode: 'VE', isoCode: 'es_VE' },
  { label: 'Swahili', countryCode: 'TZ', isoCode: 'sw' },
  { label: 'Tagalog', countryCode: 'PH', isoCode: 'tl' },
  { label: 'Tamil', countryCode: 'IN', isoCode: 'ta' },
  { label: 'Te reo', countryCode: 'NZ', isoCode: 'mi' },
  { label: 'Telugu', countryCode: 'IN', isoCode: 'te' },
  { label: 'Urdu', countryCode: 'PK', isoCode: 'ur' },
  { label: 'Welsh', countryCode: 'GB', isoCode: 'cy' },
  { label: 'Xhosa', countryCode: 'ZA', isoCode: 'xh' },
  { label: 'Yiddish', countryCode: 'IL', isoCode: 'ji' },
  { label: 'Zulu', countryCode: 'ZA', isoCode: 'zu' },
  { label: 'Chinese (Simplified)', countryCode: 'CN', isoCode: 'zh_CN' },
  { label: 'Chinese (Traditional)', countryCode: 'TW', isoCode: 'zh_TW' },
  { label: 'Danish', countryCode: 'DK', isoCode: 'da' },
  { label: 'Dutch', countryCode: 'NL', isoCode: 'nl_NL' },
  { label: 'English', countryCode: 'US', isoCode: 'en_US' },
  { label: 'Finnish', countryCode: 'FI', isoCode: 'fi' },
  { label: 'French', countryCode: 'FR', isoCode: 'fr' },
  { label: 'German', countryCode: 'DE', isoCode: 'de' },
  { label: 'Italian', countryCode: 'IT', isoCode: 'it' },
  { label: 'Japanese', countryCode: 'JP', isoCode: 'ja' },
  { label: 'Korean', countryCode: 'KR', isoCode: 'ko' },
  { label: 'Norwegian', countryCode: 'NO', isoCode: 'no' },
  { label: 'Portuguese (Brazil)', countryCode: 'BR', isoCode: 'pt_BR' },
  { label: 'Russian', countryCode: 'RU', isoCode: 'ru' },
  { label: 'Spanish', countryCode: 'ES', isoCode: 'es' },
  { label: 'Spanish (Mexico)', countryCode: 'MX', isoCode: 'es_MX' },
  { label: 'Swedish', countryCode: 'SE', isoCode: 'sv' },
  { label: 'Thai', countryCode: 'TH', isoCode: 'th' },
  { label: 'Arabic', countryCode: 'SA', isoCode: 'ar' },
  { label: 'Bulgarian', countryCode: 'BG', isoCode: 'bg' },
  { label: 'Croatian', countryCode: 'HR', isoCode: 'hr' },
  { label: 'Czech', countryCode: 'CZ', isoCode: 'cs' },
  { label: 'English (UK)', countryCode: 'GB', isoCode: 'en_GB' },
  { label: 'Greek', countryCode: 'GR', isoCode: 'el' },
  { label: 'Hebrew', countryCode: 'IL', isoCode: 'iw' },
  { label: 'Hungarian', countryCode: 'HU', isoCode: 'hu' },
  { label: 'Indonesian', countryCode: 'ID', isoCode: 'in' },
  { label: 'Polish', countryCode: 'PL', isoCode: 'pl' },
  { label: 'Portuguese (European)', countryCode: 'PT', isoCode: 'pt_PT' },
  { label: 'Romanian', countryCode: 'RO', isoCode: 'ro' },
  { label: 'Slovak', countryCode: 'SK', isoCode: 'sk' },
  { label: 'Slovenian', countryCode: 'SI', isoCode: 'sl' },
  { label: 'Turkish', countryCode: 'TR', isoCode: 'tr' },
  { label: 'Ukrainian', countryCode: 'UA', isoCode: 'uk' },
  { label: 'Vietnamese', countryCode: 'VN', isoCode: 'vi' },
];

export const deepLLanguages: ILanugage[] = [
  { label: 'Arabic', countryCode: 'SA', isoCode: 'ar' },
  { label: 'Bulgarian', countryCode: 'BG', isoCode: 'bg' },
  { label: 'Czech', countryCode: 'CZ', isoCode: 'cs' },
  { label: 'Danish', countryCode: 'DK', isoCode: 'da' },
  { label: 'German', countryCode: 'DE', isoCode: 'de' },
  { label: 'Greek', countryCode: 'GR', isoCode: 'el' },
  { label: 'English (UK)', countryCode: 'GB', isoCode: 'en_GB' },
  { label: 'English', countryCode: 'US', isoCode: 'en_US' },
  { label: 'Spanish', countryCode: 'ES', isoCode: 'es' },
  { label: 'Estonian', countryCode: 'EE', isoCode: 'et' },
  { label: 'Finnish', countryCode: 'FI', isoCode: 'fi' },
  { label: 'French', countryCode: 'FR', isoCode: 'fr' },
  { label: 'Hungarian', countryCode: 'HU', isoCode: 'hu' },
  { label: 'Indonesian', countryCode: 'ID', isoCode: 'id' },
  { label: 'Italian', countryCode: 'IT', isoCode: 'it' },
  { label: 'Japanese', countryCode: 'JP', isoCode: 'ja' },
  { label: 'Korean', countryCode: 'KR', isoCode: 'ko' },
  { label: 'Lithuanian', countryCode: 'LT', isoCode: 'lt' },
  { label: 'Latvian', countryCode: 'LV', isoCode: 'lv' },
  { label: 'Norwegian Bokmål', countryCode: 'NO', isoCode: 'nb' },
  { label: 'Dutch', countryCode: 'NL', isoCode: 'nl' },
  { label: 'Polish', countryCode: 'PL', isoCode: 'pl' },
  { label: 'Portuguese', countryCode: 'PT', isoCode: 'pt' },
  { label: 'Portuguese (Brazil)', countryCode: 'BR', isoCode: 'pt_BR' },
  { label: 'Portuguese (European)', countryCode: 'PT', isoCode: 'pt_PT' },
  { label: 'Romanian', countryCode: 'RO', isoCode: 'ro' },
  { label: 'Russian', countryCode: 'RU', isoCode: 'ru' },
  { label: 'Slovak', countryCode: 'SK', isoCode: 'sk' },
  { label: 'Slovenian', countryCode: 'SI', isoCode: 'sl' },
  { label: 'Swedish', countryCode: 'SE', isoCode: 'sv' },
  { label: 'Turkish', countryCode: 'TR', isoCode: 'tr' },
  { label: 'Ukrainian', countryCode: 'UA', isoCode: 'uk' },
  { label: 'Chinese (Simplified)', countryCode: 'CN', isoCode: 'zh_HANS' },
  { label: 'Chinese (Traditional)', countryCode: 'CN', isoCode: 'zh_HANT' }
];

export const googleTranslateLanguages: ILanugage[] = [
  { label: 'Afrikaans', countryCode: 'ZA', isoCode: 'af' },
  { label: 'Albanian', countryCode: 'AL', isoCode: 'sq' },
  { label: 'Amharic', countryCode: 'ET', isoCode: 'am' },
  { label: 'Arabic', countryCode: 'SA', isoCode: 'ar' },
  { label: 'Armenian', countryCode: 'AM', isoCode: 'hy' },
  { label: 'Assamese', countryCode: 'IN', isoCode: 'as' },
  { label: 'Aymara', countryCode: 'BO', isoCode: 'ay' },
  { label: 'Azerbaijani', countryCode: 'AZ', isoCode: 'az' },
  { label: 'Bambara', countryCode: 'ML', isoCode: 'bm' },
  { label: 'Basque', countryCode: 'ES', isoCode: 'eu' },
  { label: 'Belarusian', countryCode: 'BY', isoCode: 'be' },
  { label: 'Bengali', countryCode: 'BD', isoCode: 'bn' },
  { label: 'Bhojpuri', countryCode: 'IN', isoCode: 'bho' },
  { label: 'Bosnian', countryCode: 'BA', isoCode: 'bs' },
  { label: 'Bulgarian', countryCode: 'BG', isoCode: 'bg' },
  { label: 'Catalan', countryCode: 'ES', isoCode: 'ca' },
  { label: 'Cebuano', countryCode: 'PH', isoCode: 'ceb' },
  { label: 'Chinese (Simplified)', countryCode: 'CN', isoCode: 'zh_CN' },
  { label: 'Chinese (Traditional)', countryCode: 'TW', isoCode: 'zh_TW' },
  { label: 'Corsican', countryCode: 'FR', isoCode: 'co' },
  { label: 'Croatian', countryCode: 'HR', isoCode: 'hr' },
  { label: 'Czech', countryCode: 'CZ', isoCode: 'cs' },
  { label: 'Danish', countryCode: 'DK', isoCode: 'da' },
  { label: 'Dhivehi', countryCode: 'MV', isoCode: 'dv' },
  { label: 'Dogri', countryCode: 'IN', isoCode: 'doi' },
  { label: 'Dutch', countryCode: 'NL', isoCode: 'nl' },
  { label: 'English', countryCode: 'US', isoCode: 'en_US' },
  { label: 'Esperanto', countryCode: 'EO', isoCode: 'eo' },
  { label: 'Estonian', countryCode: 'EE', isoCode: 'et' },
  { label: 'Ewe', countryCode: 'GH', isoCode: 'ee' },
  { label: 'Filipino (Tagalog)', countryCode: 'PH', isoCode: 'fil' },
  { label: 'Finnish', countryCode: 'FI', isoCode: 'fi' },
  { label: 'French', countryCode: 'FR', isoCode: 'fr' },
  { label: 'Frisian', countryCode: 'NL', isoCode: 'fy' },
  { label: 'Galician', countryCode: 'ES', isoCode: 'gl' },
  { label: 'Georgian', countryCode: 'GE', isoCode: 'ka' },
  { label: 'German', countryCode: 'DE', isoCode: 'de' },
  { label: 'Greek', countryCode: 'GR', isoCode: 'el' },
  { label: 'Guarani', countryCode: 'PY', isoCode: 'gn' },
  { label: 'Gujarati', countryCode: 'IN', isoCode: 'gu' },
  { label: 'Haitian Creole', countryCode: 'HT', isoCode: 'ht' },
  { label: 'Hausa', countryCode: 'NG', isoCode: 'ha' },
  { label: 'Hawaiian', countryCode: 'US', isoCode: 'haw' },
  { label: 'Hebrew', countryCode: 'IL', isoCode: 'he' },
  { label: 'Hindi', countryCode: 'IN', isoCode: 'hi' },
  { label: 'Hmong', countryCode: 'CN', isoCode: 'hmn' },
  { label: 'Hungarian', countryCode: 'HU', isoCode: 'hu' },
  { label: 'Icelandic', countryCode: 'IS', isoCode: 'is' },
  { label: 'Igbo', countryCode: 'NG', isoCode: 'ig' },
  { label: 'Ilocano', countryCode: 'PH', isoCode: 'ilo' },
  { label: 'Indonesian', countryCode: 'ID', isoCode: 'id' },
  { label: 'Irish', countryCode: 'IE', isoCode: 'ga' },
  { label: 'Italian', countryCode: 'IT', isoCode: 'it' },
  { label: 'Japanese', countryCode: 'JP', isoCode: 'ja' },
  { label: 'Javanese', countryCode: 'ID', isoCode: 'jv' },
  { label: 'Kannada', countryCode: 'IN', isoCode: 'kn' },
  { label: 'Kazakh', countryCode: 'KZ', isoCode: 'kk' },
  { label: 'Khmer', countryCode: 'KH', isoCode: 'km' },
  { label: 'Kinyarwanda', countryCode: 'RW', isoCode: 'rw' },
  { label: 'Konkani', countryCode: 'IN', isoCode: 'gom' },
  { label: 'Korean', countryCode: 'KR', isoCode: 'ko' },
  { label: 'Krio', countryCode: 'SL', isoCode: 'kri' },
  { label: 'Kurdish', countryCode: 'IQ', isoCode: 'ku' },
  { label: 'Kurdish (Sorani)', countryCode: 'IQ', isoCode: 'ckb' },
  { label: 'Kyrgyz', countryCode: 'KG', isoCode: 'ky' },
  { label: 'Lao', countryCode: 'LA', isoCode: 'lo' },
  { label: 'Latin', countryCode: 'VA', isoCode: 'la' },
  { label: 'Latvian', countryCode: 'LV', isoCode: 'lv' },
  { label: 'Lingala', countryCode: 'CD', isoCode: 'ln' },
  { label: 'Lithuanian', countryCode: 'LT', isoCode: 'lt' },
  { label: 'Luganda', countryCode: 'UG', isoCode: 'lg' },
  { label: 'Luxembourgish', countryCode: 'LU', isoCode: 'lb' },
  { label: 'Macedonian', countryCode: 'MK', isoCode: 'mk' },
  { label: 'Maithili', countryCode: 'IN', isoCode: 'mai' },
  { label: 'Malagasy', countryCode: 'MG', isoCode: 'mg' },
  { label: 'Malay', countryCode: 'MY', isoCode: 'ms' },
  { label: 'Malayalam', countryCode: 'IN', isoCode: 'ml' },
  { label: 'Maltese', countryCode: 'MT', isoCode: 'mt' },
  { label: 'Maori', countryCode: 'NZ', isoCode: 'mi' },
  { label: 'Marathi', countryCode: 'IN', isoCode: 'mr' },
  { label: 'Meiteilon (Manipuri)', countryCode: 'IN', isoCode: 'mni_Mtei' },
  { label: 'Mizo', countryCode: 'IN', isoCode: 'lus' },
  { label: 'Mongolian', countryCode: 'MN', isoCode: 'mn' },
  { label: 'Myanmar (Burmese)', countryCode: 'MM', isoCode: 'my' },
  { label: 'Nepali', countryCode: 'NP', isoCode: 'ne' },
  { label: 'Norwegian', countryCode: 'NO', isoCode: 'no' },
  { label: 'Nyanja (Chichewa)', countryCode: 'MW', isoCode: 'ny' },
  { label: 'Odia (Oriya)', countryCode: 'IN', isoCode: 'or' },
  { label: 'Oromo', countryCode: 'ET', isoCode: 'om' },
  { label: 'Pashto', countryCode: 'AF', isoCode: 'ps' },
  { label: 'Persian', countryCode: 'IR', isoCode: 'fa' },
  { label: 'Polish', countryCode: 'PL', isoCode: 'pl' },
  { label: 'Portuguese (Portugal, Brazil)', countryCode: 'PT', isoCode: 'pt' },
  { label: 'Punjabi', countryCode: 'IN', isoCode: 'pa' },
  { label: 'Quechua', countryCode: 'PE', isoCode: 'qu' },
  { label: 'Romanian', countryCode: 'RO', isoCode: 'ro' },
  { label: 'Russian', countryCode: 'RU', isoCode: 'ru' },
  { label: 'Samoan', countryCode: 'WS', isoCode: 'sm' },
  { label: 'Sanskrit', countryCode: 'IN', isoCode: 'sa' },
  { label: 'Scots Gaelic', countryCode: 'GB', isoCode: 'gd' },
  { label: 'Sepedi', countryCode: 'ZA', isoCode: 'nso' },
  { label: 'Serbian', countryCode: 'RS', isoCode: 'sr' },
  { label: 'Sesotho', countryCode: 'LS', isoCode: 'st' },
  { label: 'Shona', countryCode: 'ZW', isoCode: 'sn' },
  { label: 'Sindhi', countryCode: 'PK', isoCode: 'sd' },
  { label: 'Sinhala (Sinhalese)', countryCode: 'LK', isoCode: 'si' },
  { label: 'Slovak', countryCode: 'SK', isoCode: 'sk' },
  { label: 'Slovenian', countryCode: 'SI', isoCode: 'sl' },
  { label: 'Somali', countryCode: 'SO', isoCode: 'so' },
  { label: 'Spanish', countryCode: 'ES', isoCode: 'es' },
  { label: 'Sundanese', countryCode: 'ID', isoCode: 'su' },
  { label: 'Swahili', countryCode: 'KE', isoCode: 'sw' },
  { label: 'Swedish', countryCode: 'SE', isoCode: 'sv' },
  { label: 'Tagalog (Filipino)', countryCode: 'PH', isoCode: 'tl' },
  { label: 'Tajik', countryCode: 'TJ', isoCode: 'tg' },
  { label: 'Tamil', countryCode: 'IN', isoCode: 'ta' },
  { label: 'Tatar', countryCode: 'RU', isoCode: 'tt' },
  { label: 'Telugu', countryCode: 'IN', isoCode: 'te' },
  { label: 'Thai', countryCode: 'TH', isoCode: 'th' },
  { label: 'Tigrinya', countryCode: 'ER', isoCode: 'ti' },
  { label: 'Tsonga', countryCode: 'ZA', isoCode: 'ts' },
  { label: 'Turkish', countryCode: 'TR', isoCode: 'tr' },
  { label: 'Turkmen', countryCode: 'TM', isoCode: 'tk' },
  { label: 'Twi (Akan)', countryCode: 'GH', isoCode: 'ak' },
  { label: 'Ukrainian', countryCode: 'UA', isoCode: 'uk' },
  { label: 'Urdu', countryCode: 'PK', isoCode: 'ur' },
  { label: 'Uyghur', countryCode: 'CN', isoCode: 'ug' },
  { label: 'Uzbek', countryCode: 'UZ', isoCode: 'uz' },
  { label: 'Vietnamese', countryCode: 'VN', isoCode: 'vi' },
  { label: 'Welsh', countryCode: 'GB', isoCode: 'cy' },
  { label: 'Xhosa', countryCode: 'ZA', isoCode: 'xh' },
  { label: 'Yiddish', countryCode: 'IL', isoCode: 'yi' },
  { label: 'Yoruba', countryCode: 'NG', isoCode: 'yo' },
  { label: 'Zulu', countryCode: 'ZA', isoCode: 'zu' }
];

export const salesForceLanguages: ILanugage[] = [
  { 'label': 'Afrikaans', 'countryCode': 'ZA', 'isoCode': 'af' },
  { 'label': 'Albanian', 'countryCode': 'AL', 'isoCode': 'sq' },
  { 'label': 'Amharic', 'countryCode': 'ET', 'isoCode': 'am' },
  { 'label': 'Arabic (Algeria)', 'countryCode': 'DZ', 'isoCode': 'ar_DZ' },
  { 'label': 'Arabic (Bahrain)', 'countryCode': 'BH', 'isoCode': 'ar_BH' },
  { 'label': 'Arabic (Egypt)', 'countryCode': 'EG', 'isoCode': 'ar_EG' },
  { 'label': 'Arabic (Iraq)', 'countryCode': 'IQ', 'isoCode': 'ar_IQ' },
  { 'label': 'Arabic (Jordan)', 'countryCode': 'JO', 'isoCode': 'ar_JO' },
  { 'label': 'Arabic (Kuwait)', 'countryCode': 'KW', 'isoCode': 'ar_KW' },
  { 'label': 'Arabic (Lebanon)', 'countryCode': 'LB', 'isoCode': 'ar_LB' },
  { 'label': 'Arabic (Libya)', 'countryCode': 'LY', 'isoCode': 'ar_LY' },
  { 'label': 'Arabic (Morocco)', 'countryCode': 'MA', 'isoCode': 'ar_MA' },
  { 'label': 'Arabic (Oman)', 'countryCode': 'OM', 'isoCode': 'ar_OM' },
  { 'label': 'Arabic (Qatar)', 'countryCode': 'QA', 'isoCode': 'ar_QA' },
  { 'label': 'Arabic (Saudi Arabia)', 'countryCode': 'SA', 'isoCode': 'ar_SA' },
  { 'label': 'Arabic (Sudan)', 'countryCode': 'SD', 'isoCode': 'ar_SD' },
  { 'label': 'Arabic (Syria)', 'countryCode': 'SY', 'isoCode': 'ar_SY' },
  { 'label': 'Arabic (Tunisia)', 'countryCode': 'TN', 'isoCode': 'ar_TN' },
  { 'label': 'Arabic (United Arab Emirates)', 'countryCode': 'AE', 'isoCode': 'ar_AE' },
  { 'label': 'Arabic (Yemen)', 'countryCode': 'YE', 'isoCode': 'ar_YE' },
  { 'label': 'Basque', 'countryCode': 'ES', 'isoCode': 'eu' },
  { 'label': 'Bengali', 'countryCode': 'BD', 'isoCode': 'bn' },
  { 'label': 'Bosnian', 'countryCode': 'BA', 'isoCode': 'bs' },
  { 'label': 'Bulgarian', 'countryCode': 'BG', 'isoCode': 'bg' },
  { 'label': 'Catalan', 'countryCode': 'ES', 'isoCode': 'ca' },
  { 'label': 'Chinese (Simplified)', 'countryCode': 'CN', 'isoCode': 'zh_CN' },
  { 'label': 'Chinese (Traditional)', 'countryCode': 'TW', 'isoCode': 'zh_TW' },
  { 'label': 'Croatian', 'countryCode': 'HR', 'isoCode': 'hr' },
  { 'label': 'Czech', 'countryCode': 'CZ', 'isoCode': 'cs' },
  { 'label': 'Danish', 'countryCode': 'DK', 'isoCode': 'da' },
  { 'label': 'Dutch', 'countryCode': 'NL', 'isoCode': 'nl' },
  { 'label': 'English', 'countryCode': 'US', 'isoCode': 'en_US' },
  { 'label': 'Finnish', 'countryCode': 'FI', 'isoCode': 'fi' },
  { 'label': 'French', 'countryCode': 'FR', 'isoCode': 'fr' },
  { 'label': 'German', 'countryCode': 'DE', 'isoCode': 'de' },
  { 'label': 'Greek', 'countryCode': 'GR', 'isoCode': 'el' },
  { 'label': 'Hebrew', 'countryCode': 'IL', 'isoCode': 'he' },
  { 'label': 'Hindi', 'countryCode': 'IN', 'isoCode': 'hi' },
  { 'label': 'Hungarian', 'countryCode': 'HU', 'isoCode': 'hu' },
  { 'label': 'Indonesian', 'countryCode': 'ID', 'isoCode': 'id' },
  { 'label': 'Italian', 'countryCode': 'IT', 'isoCode': 'it' },
  { 'label': 'Japanese', 'countryCode': 'JP', 'isoCode': 'ja' },
  { 'label': 'Korean', 'countryCode': 'KR', 'isoCode': 'ko' },
  { 'label': 'Latvian', 'countryCode': 'LV', 'isoCode': 'lv' },
  { 'label': 'Lithuanian', 'countryCode': 'LT', 'isoCode': 'lt' },
  { 'label': 'Macedonian', 'countryCode': 'MK', 'isoCode': 'mk' },
  { 'label': 'Malay', 'countryCode': 'MY', 'isoCode': 'ms' },
  { 'label': 'Malayalam', 'countryCode': 'IN', 'isoCode': 'ml' },
  { 'label': 'Norwegian', 'countryCode': 'NO', 'isoCode': 'no' },
  { 'label': 'Persian', 'countryCode': 'IR', 'isoCode': 'fa' },
  { 'label': 'Polish', 'countryCode': 'PL', 'isoCode': 'pl' },
  { 'label': 'Portuguese', 'countryCode': 'PT', 'isoCode': 'pt_PT' },
  { 'label': 'Romanian', 'countryCode': 'RO', 'isoCode': 'ro' },
  { 'label': 'Russian', 'countryCode': 'RU', 'isoCode': 'ru' },
  { 'label': 'Serbian', 'countryCode': 'RS', 'isoCode': 'sr' },
  { 'label': 'Slovak', 'countryCode': 'SK', 'isoCode': 'sk' },
  { 'label': 'Slovenian', 'countryCode': 'SI', 'isoCode': 'sl' },
  { 'label': 'Spanish', 'countryCode': 'ES', 'isoCode': 'es' },
  { 'label': 'Swahili', 'countryCode': 'KE', 'isoCode': 'sw' },
  { 'label': 'Swedish', 'countryCode': 'SE', 'isoCode': 'sv' },
  { 'label': 'Thai', 'countryCode': 'TH', 'isoCode': 'th' },
  { 'label': 'Turkish', 'countryCode': 'TR', 'isoCode': 'tr' },
  { 'label': 'Ukrainian', 'countryCode': 'UA', 'isoCode': 'uk' },
  { 'label': 'Vietnamese', 'countryCode': 'VN', 'isoCode': 'vi' },
  { 'label': 'Zulu', 'countryCode': 'ZA', 'isoCode': 'zu' }
]

